import axios from 'axios'
import apiURI from '../Api';

export default class SalePromoterService {
  async postRegisterSalePromoter(referee, username, email, documentNumber, fname, lname, password, currency, language, country,
    internationalCode, localCode, phoneNumber, latitude, longitude) {
    const registerSalePromoter = {
      currency: currency,
      plan: { id: 3 },
      referee: { username: referee },
      latitude: typeof latitude === 'string' ? parseFloat(latitude) : latitude,
      longitude: typeof longitude === 'string' ? parseFloat(longitude) : longitude,
      salePromoter: {
        originRegister: JSON.parse(sessionStorage.getItem('__extraconomy_ismobile__')) ? "MOBILE" : "WEB",
        documentNumber: null,
        email: email,
        firstname: fname,
        password: password,
        surname: lname,
        username: username,
        language: language,
        country: country,
        internationalCode: internationalCode,
        localCode: localCode,
        phoneNumber: phoneNumber,
      },
    }

    const response = await axios.post(`${apiURI}/salepromoter/register`, registerSalePromoter);
    return response.data;
  }

  async postUploadDocumentPic(token, image, username) {
    let data = new FormData();
    data.append('document', image);

    const response = await axios({
      method: 'post', url: `${apiURI}/salepromoter/uploaddocument?username=${username}`, data: data,
      headers: { 'Authorization': 'Bearer ' + token }
    });
    return response.data;
  }

  async postUploadDocumentPic2(token, image, username) {
    let data = new FormData();
    data.append('document', image);

    const response = await axios({
      method: 'post', url: `${apiURI}/salepromoter/uploaddocument2?username=${username}`, data: data,
      headers: { 'Authorization': 'Bearer ' + token }
    });
    return response.data;
  }
}
