import { useEffect, useState } from "react";
import GetUserInfoService from "../service/GetUserInfoService";
import { useFavoritesDispatch } from "../contexts/FavoritesContext/FavoritesContext";
import { addFavorite, removeFavorite } from "../contexts/FavoritesContext/actions";

const useFavorite = ({
  productId,
  onSuccess,
  OnItemRemovedFromFavoriteError,
  onFavoriteError,
  onUnfavoriteError,
  onIsFavoriteError,
}) => {
  const [heartIcon, setHeartIcon] = useState("pi pi-heart");
  const [loadingFavorite, setLoadingFavorite] = useState(true);
  const [heartColor, setHeartColor] = useState("#616161");

  const dispatch = useFavoritesDispatch();

  const getUserInfoService = new GetUserInfoService();

  const isFavorite = async (cancel) => {
    if (productId && sessionStorage.getItem("__extraconomy_token")) {
      try {
        setLoadingFavorite(true);

        const res = await getUserInfoService.getIsFavorite(
          sessionStorage.getItem("__extraconomy_token"),
          productId,
          sessionStorage.getItem("__extraconomy_username")
        );
        if (cancel) return;
        if (res.messageCode !== 200001) {
          onIsFavoriteError(res.messageCode);
          if (res.messageCode === 500000) console.log(res);
          setLoadingFavorite(false);
        } else {
          if (res.result) {
            setHeartColor("#d32f2f");
            setHeartIcon("pi pi-heart-fill");
          }
          setLoadingFavorite(false);
        }
      } catch (e) {
        if (e.status === 401 || e.status === 403) {
          sessionStorage.removeItem("__extraconomy_token");
          sessionStorage.removeItem("__extraconomy_username");
          window.location.replace("/login");
        }
        setLoadingFavorite(false);
        console.log(e);
      }
    }
  };

  const favorite = async () => {
    try {
      setLoadingFavorite(true);

      let resFavorite = null;
      let resUnfavorite = null;

      if (heartColor === "#616161") {
        resFavorite = await getUserInfoService.postFavoriteProduct(
          sessionStorage.getItem("__extraconomy_token"),
          productId,
          sessionStorage.getItem("__extraconomy_username")
        );
      } else {
        resUnfavorite = await getUserInfoService.deleteFavorite(
          sessionStorage.getItem("__extraconomy_token"),
          productId,
          sessionStorage.getItem("__extraconomy_username")
        );
      }

      if (resFavorite && resFavorite.messageCode !== 200000) {
        onFavoriteError(resFavorite.messageCode);
        if (resFavorite.messageCode === 500000) console.log(resFavorite);
        setLoadingFavorite(false);
        return false;
      }

      if (resUnfavorite && resUnfavorite.messageCode !== 200003) {
        onUnfavoriteError(resUnfavorite.messageCode);
        if (resUnfavorite.messageCode === 500000) console.log(resUnfavorite);
        setLoadingFavorite(false);
        return false;
      }

      if (resFavorite) {
        onSuccess();
        setHeartIcon("pi pi-heart-fill");
        setHeartColor("#d32f2f");
        setLoadingFavorite(false);
        dispatch(addFavorite());
        return true;
      }

      if (resUnfavorite) {
        OnItemRemovedFromFavoriteError();
        setHeartIcon("pi pi-heart");
        setHeartColor("#616161");
        setLoadingFavorite(false);
        dispatch(removeFavorite());
        return true;
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        sessionStorage.removeItem("__extraconomy_token");
        sessionStorage.removeItem("__extraconomy_username");
        window.location.replace("/login");
      }
      setLoadingFavorite(false);
      console.log(e);
    }
  };

  useEffect(() => {
    let cancel = false;
    isFavorite(cancel);
    return () => {
      setLoadingFavorite(false);
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return { favorite, heartIcon, loadingFavorite, heartColor };
};

export default useFavorite;
