const ordersDictPT = {
  youDontHave: "Você não possui",
  orderDelivered: "Pedido foi recebido?",
  receiveThisOrder: "Este pedido foi entregue ao endereço informado?",
  sellerProfilePic: "Foto de perfil do vendedor",
  buyerProfilePic: "Foto do perfil do comprador",
  itemsQuantity: "Quantidade de itens",
  orderInfo: "Informações do pedido",
  backToOrdersList: "Voltar à lista de pedidos",
  amountCash: "Pago no checkout (PayPal)",
  amountWallet: "Pago com a carteira",
  amountWalletExtracoin: "Pago com Extracredit",
  orderDetails: "Ver detalhes",
  sendOrder: "Enviar pedido",
  receiveOrder: "Informar que o pedido foi recebido",
  confirmOrderWasSent: "Por favor confirme que o pedido foi enviado fornecendo os seguintes dados",
  shippingCompany: "Transportadora",
  trackingObject: "ID de rastreamento",
  productReceivedSuccessfully: "Produto recebido com sucesso!",
  productSentSuccessfully: "Produto enviado com sucesso!",
  paid: "Pago",
  sent: "Enviado",
  received: "Recebido",
  howWasPaid: "Formas de pagamento",
  receiveDownloadLinks: "Receber links para download",
  receiveDownloadLinksConfirmation:
    'Confirme para baixar seus produtos e atribuir este pedido como "recebido".',
  pressTheReceiveDownloadLinks:
    'Pressione o botão "Receber links para download" abaixo para baixar seus produtos',
  download: "Baixar",
  confirmRating: "Confirmar Avaliação",
  orderRatedSuccessfully: "Pedido avaliado com sucesso!",
  rate: "Avaliar",
  rateProduct: "Avaliar produto",
  rateSeller: "Avaliar vendedor",
  comment: "Feedback para a loja",
  commentProductService: "Feedback sobre o produto/serviço",
  optional: "opcional",
  pleaseProvideRating: "Forneça uma classificação para o produto e o vendedor.",
  rateOrder: "Avaliar Pedido",
  labelExplanation:
    "Antes de agendar a retirada do pacote, por favor, pressione o botão abaixo para baixar uma etiqueta que você deve imprimir e colar em seu pacote. ESTA ETAPA É OBRIGATÓRIA, pois a transportadora só fará a retirada do seu produto se a etiqueta estiver visível.",
  downloadLabel: "Baixar Etiqueta",
  schedulePackagePickup: "Agendar o prazo de retirada do pacote",
  shippingInstructions: "Instruções de envio",
  schedulePickupTimeFrameWarning:
    "Não forneça finais de semana e feriados no prazo. Coloque um prazo com folga, ou seja, um período de pelo menos dois dias.",
  pickupInfo: "Informações do envio",
  pickupTimeFrame: "Prazo da retirada do pacote",
  shippingMethod: "Método de envio",
  trackingNumber: "Número de rastreamento",
  trackingUrl: "URL de rastreamento",
  dateMustNotBeNull: "Forneça um prazo para a retirada do pacote.",
  registerPickupConfirmation: "Confirmar este horário de coleta?",
  errorOfSystem: "Erro do sistema",
  changeStatusToSent: "Mudar status para enviado",
  confirmOrderWasSent2: "Você deseja confirmar que o pedido foi enviado?",
  productIsYetToBeShipped: "Seu produto ainda vai ser enviado",
  searchByStoreName: "Buscar por nome da loja",
  talkToSeller: "Conversar com o vendedor",
  talkToBuyer: "Conversar com o comprador",
  talkTo: "Conversar com",
  send: "Enviar",
  noMessages: "Nenhuma mensagem",
  cannotExceed250Chars: "A sua mensagem não pode exceder 250 caracteres",
  messageHasPersonalNumber:
    "A sua mensagem contém um número de telefone pessoal. Por favor, remova-o e tente novamente.",
  serverError: "Houve um erro no servidor, tente novamente mais tarde.",
  dowloadYourLabelAndPutInYourProduct:
    "Baixe a etiqueta, coloque no seu produto e espere seu produto ser coletado",
  readyForPickup: "Pronto para coleta",
  sellerIsPreparingYourOrder: "O vendedor está preparando o seu pedido",
  productWasPickedUp: "Informar que o produto foi retirado",
  youconfirmOrderWasSent: "Você confirma que retirou esse pedido?",
  waitingForBuyerWithdrawal: "Aguardando retirada do comprador",
  productIsReadyToPickup: "Seu produto está pronto para ser retirado",
};

export default ordersDictPT;
