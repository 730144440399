import { createContext, useContext, useMemo, useState } from "react";

export const UserContext = createContext(null);

const initialState = null;

export function UserProvider(props) {
  const [userInfo, setUserInfo] = useState(initialState);

  const value = useMemo(
    () => ({
      userInfo,
      setUserInfo,
    }),
    [setUserInfo, userInfo]
  );

  return <UserContext.Provider value={value} {...props} />;
}

export function useUserContext() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a AppProvider");
  }

  return context;
}
