const landingDictEN = {
  home: "Home",
  benefits: "Benefits",
  benefitsExplanation: "Our platform is a modern, easily accessible and profitable tool.",
  firstBenefit: "Greater commercial integration",
  secondBenefit: "Lighter and more powerful economy",
  thirdBenefit: "Instant and consistent profit opportunity",
  fourthBenefit: "Generate credit",
  features: "Features",
  howItWorks: "How it works",
  meetOurPlans: "Meet our plans",
  join: "Our plans",
  plans: "Plans",
  login: "Sign in",
  whatIsIt: "The marketplace that always rewards you",
  whatIsItExplanation:
    "Extraconomy is an online cooperative economy platform with a system of rewards and geolocations that allows individuals and companies to increase their economic power and their network of customers in an automatic and progressive manner, through the various activities carried out on the marketplace.",
  why: "Why?",
  greaterBusiness: "Greater business integration",
  lighterPowerful: "Lighter and more powerful economy",
  instantProfit: "Instant and consistent profit opportunity",
  genCredit: "Generate credit",
  whyShould: "Why should I join ExtraConomy?",
  seeBelow: "See below the features of our social and economic circle:",
  extracredit: "Extracredit",
  extracoinExplanation:
    "Register and earn Extracredit (see conditions). Use the credit to buy and/or share with your friends.",
  indicate: "Earn Extracredit Credits",
  youMayGain: "Refer users to your network and earn Extracredit and real money.",
  economicNetwork: "Economic Network",
  networkOfUsers: "Build user network to buy and sell products and services.",
  transactionSafety: "Transaction Security",
  ourPlatform: "Our platform is robust and secure. You can buy, sell and transfer money securely.",
  findEverything: "Find Everything You Need",
  weHavePowerfulTool:
    "We have a powerful tool to find the product you are looking to purchase in a few clicks.",
  buyerReviews: "Buyer Reviews",
  checkTheReviews: "Check reviews from users who have purchased the product you are looking for.",
  geolocation: "Geolocation",
  effectiveTools: "Effective tools to automatically grow your economic network.",
  exceptionalTrading: "Exceptional Business",
  saferMoreFrequent: "Safer, frequent and advantageous exchanges.",
  economicInfluence: "Economic Influence",
  sellFaster: "Make sales faster, buy more easily, always making a profit.",
  howItWorksExplanation:
    "You receive rewards for each activity that takes place on the platform, in the form of money and Extracredit credits that can be used within the system as an economic complement in commercial exchanges.",
  learnMore: "Learn more",
  doubts: "Doubts? ",
  contactToUs: "Contact us.",
  belowArePlans: "Join the Extraconomy. Below are the types of plans available on our service.",
  contact: "Contact",
  contactWhatTele:
    "For information about the project and about opening the market for Partners and Managers in a Country, write to the following Whatsapp and Telegram contacts.",
  joinExtraconomy: "Join the Extraconomy.",
  scanQRCode: "Scan the QR Code below to contact us via WhatsApp or Telegram:",
  highlights: "Highlights",
  by: " by ",
  video: "Video",
  introVideo: "Intro video about our platform:",
  backToHomePage: "Back to the Home Page",
  qrCodeExplanation:
    "For information about the project and about opening the market for Partners and Managers in a Country, write to the following Whatsapp and Telegram contacts:",
  socialMedia: "Social Media",
  rightsReserved: "All Rights Reserved",
  checkApp: "Check our app",
  customerDescription: "Just for shopping",
  partnerDescription: "For sales and purchases",
  managerDescription: "E-commerce + Purchasing",
  benefitsExtracoinSection: "Benefits when purchasing Extracoin",
  extracoinBenefitTitle1: "2.000 Extracoins: Become a Partner at Extraconomy!",
  extracoinBenefitDescription1:
    "Holders of 2.000 Extracoins receive a free voucher to become Partners on the Extraconomy Marketplace. Join the revolution and actively participate in shaping the future of the digital economy.",
  extracoinBenefitTitle2: "5.000 Extracoins: Unlock Seller Status at Extraconomy!",
  extracoinBenefitDescription2:
    "Holders of 5.000 Extracoins are rewarded with a free voucher to become Sellers on the Extraconomy platform. Showcase your products and services to a global audience.",
  extracoinBenefitTitle3: "15.000 Extracoins: Reach Manager Status at Extraconomy!",
  extracoinBenefitDescription3:
    "Holders of 15.000 Extracoins receive an exclusive voucher to become Managers at Extraconomy. Immerse yourself in the management universe and contribute to the growth of the entire ecosystem.",
  voucherExtracoinText1: "Already have a ",
  clickHere: "Click here",
  andRegister: "and register",
  voucherEmptyError: "Voucher cannot be empty",
  extracoinVoucherError: "Invalid voucher",
  startsWith: "Starts with",
  contains: "Contains",
  notContains: "Does not contain",
  endsWith: "Ends with",
  equals: "Equals",
  notEquals: "Not equals",
  noFilter: "No filter",
  joinTheLaunchpad: "Join the Launchpad of our Extracoin token on Cryptosmart!",
  popupDesc:
    "When purchasing EXC tokens at a special price, you will receive a free voucher in return to open an online store on the Extraconomy marketplace, activating your daily income!",
  exclusiveBenefits: "Exclusive Benefits",
  popupPartnerTitle: "Become a Partner!",
  popupPartnerDesc:
    "*When purchasing a minimum of 2,000 Extracoin you will receive a free voucher to open a sales outlet on the Extraconomy marketplace.",
  popupSellerTitle: "Unlock the Seller profile!",
  popupSellerDesc:
    "*When purchasing at least 5,000 Extracoin you will receive a free voucher to open an e-commerce on the Extraconomy platform.",
  popupManagerTitle: "Reach Manager status!",
  popupManagerDesc:
    "*When purchasing a minimum of 15,000 Extracoin you will receive an exclusive voucher to open an online store at Extraconomy with all benefits included.",
  promotionValidUntilEndLaunchpad: "Promotion valid until the end of Launchpad",
  downloadAppAndEarn: "Download the app now and earn 50 euros in credits immediately",
  frequentlyAskedQuestion: "Frequently Asked Questions",
  faqTitle1: "Is the Extraconomia platform safe?",
  faqAnswer1:
    "Yes, we have a technical team specialized in information security. All content, data and financial operations on the platform are protected against multiple levels of security, so that we can mitigate as much as possible any type of computer attack that can be verified.",
  faqTitle2: "You want to acquire Extraconomy, how can I participate in the platform?",
  faqAnswer2:
    "If you discovered Extraconomy through someone, they know how to get you on the platform. Otherwise, download our App and register by entering your details to access the Store directly. You will receive a welcome credit to use on your future purchases!",
  faqTitle3: "Are there registration fees to be able to sell my products or services?",
  faqAnswer3:
    "Yes, to have a space for your storefront or for your e-commerce there are 3 sales plans with different values and benefits depending on the needs of each individual and business user. You can discover the Plans directly here on this website.",
  faqTitle4:
    "When I carry out promotional and sales activities on the Extraconomy platform, do I have an employment relationship?",
  faqAnswer4:
    "No, if you have subscribed to one of the 3 sales plans you will be autonomous and will be fully responsible for your expenses. Only if you decide to work with us as a Promoter will you receive a Sales Representative contract.",
  faqTitle5: "How can I request deletion of my account?",
  faqAnswer5:
    "To cancel your Extraconomy account, simply log in to your Personal Area and then click Edit Profile to request the cancellation of the account at no additional cost. Or you can send an email to support@extraconomyglobal.com using the same email you use to access Extraconomy, with the subject “Account cancellation”.",
  faqTitle6: "I'm new to the platform, is there any support?",
  faqAnswer6:
    "Yes, we have humanized and dedicated support to help you whenever you need it through the Whatsapp and Telegram channels that you can find among our contacts here on the website.",
  faqTitle7: "I provided the wrong email, what now?",
  faqAnswer7:
    "To change your email, you will need to send an email to support@extraconomyglobal.com with the subject “Change email”, providing all the registration data used in registration and the correct email.",
  faqTitle8: "What are the payment options?",
  faqAnswer8:
    "Through PayPal, our international payment gateway, you can pay for your order by credit card, debit card or bank account.",
  faqTitle9: "How much does shipping cost to my region?",
  faqAnswer9:
    "For this calculation, the easiest way is to simulate your order. Choose the items with Shipping Paid, add them to the cart and complete the purchase, enter your details correctly (very important) and at the end of the order the website will show the correct shipping cost. If you are just simulating, do not click Checkout and it will not result in any real purchase on your part. Then edit the items and quantities to see the differences, feel free.",
  faqTitle10: "How do credits acquired on Extraconomy work?",
  faqAnswer10:
    "Internal credits are acquired through the various activities carried out on the platform (Registrations, Buy-Sell and Sharing) in the form of cashbacks, prizes and bonuses, which can be used in a complementary way in purchases within the platform or in marketing activities based on terms of use provided for each user profile. ",
  faqTitle11:
    "How can I monitor and manage my payments, commissions and bonuses received on Extraconomy?",
  faqAnswer11:
    "When registering via the Web and the App, you receive a virtual office in your Personal Area where you can monitor all your direct financial income and those from your economic circle.",
  faqTitle12: "How do I receive my financial income?",
  faqAnswer12:
    "Direct and indirect economic income is credited to your PayPal account, which you must enter in your Personal Area. If you don't have a PayPal account, you can create one for free at www.paypal.com.",
  SeeTheRegulationsHere: "See the regulations here",
  joinTheLaunchpadButton: "Join the Launchpad",
  tax: "Tax",
  workWithUs: "Work with us",
  jobOpportunity: "Job opportunity",
  workWithUsText1:
    "The Extraconomy system offers the possibility of participating in online and territorial development through key figures (web promoter, ambassador, sales agent, micro-influencer, market affiliates) who will automatically benefit from an exclusive, scalable and rewarding commission system.",
  freePlan: "Free",
  salesRepresentativeContract: "Sales representative contract",
  earnCommissionsAndCredits: "Earn commissions and credits",
  developYourNetwork: "Develop your network",
  financialCareerAwards: "Financial and career awards",
};

export default landingDictEN;
