import { useState } from "react";
import SearchService from "../service/SearchService";
import { useHistory } from "react-router-dom";

const useConfirmSearch = ({ productId, onError }) => {
  const [loadingConfirmSearch, setLoadingConfirmSearch] = useState(false);

  const history = useHistory();
  const searchService = new SearchService();

  const confirmSearch = async () => {
    try {
      setLoadingConfirmSearch(true);

      if (sessionStorage.getItem("__extraconomy_search")) {
        const searchObj = JSON.parse(sessionStorage.getItem("__extraconomy_search"));

        const res = await searchService.postConfirmSearch(searchObj.id, productId);

        if (res.messageCode !== 200000) {
          onError(res.messageCode);
          if (res.messageCode === 500000) console.log(res);
          setLoadingConfirmSearch(false);
        } else sessionStorage.removeItem("__extraconomy_search");
      }

      window.location.href = `/product?id=${productId}`;
      //  history.push(`/product?id=${productId}`);
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        sessionStorage.removeItem("__extraconomy_token");
        sessionStorage.removeItem("__extraconomy_username");
        history.push("/login");
      }
      console.log(e);
    }
  };

  return {
    loadingConfirmSearch,
    confirmSearch,
  };
};

export default useConfirmSearch;
