import React from "react";
import { formatDate } from "../../../../utilities/formatDate";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import localeText from "../../../../locale";
import { formatPrice } from "../../../../utilities/formatPrice";

export const formatAddress = (order) => {
  return `${order.address ? order.address + ", " : ""}${order.number ? order.number + ", " : ""}${
    order.district ? order.district + ", " : ""
  }${order.city ? order.city + ", " : ""}${order.state ? order.state + ", " : ""}${
    order.postalCode ? order.postalCode + ", " : ""
  }${order.country ? order.country : ""}`;
};

const ThankYouPage = ({ order, currencyCodeUser }) => {
  const history = useHistory();

  const anyProductIsPickup = order?.itens?.some(
    (product) => product?.product?.shippingOption === "PICKUP"
  );

  const handleRedicrectToMyPurchases = () => {
    history.push("/purchases");
  };
  const amountShipment = order?.amountShipment ? order.amountShipment : 0;
  const totalPrice = order.amountTotal + amountShipment;
  const productIsDownloadable = order?.itens?.some((product) => product?.product?.downloadable);

  return (
    <div className="flex flex-column">
      <h1 className="text-center text-xl mb-4">
        {localeText("thankYouForYourPurchase")} {order.buyer.username}!
      </h1>

      <h4 className="text-lg">{localeText("orderInformation")}</h4>
      <p className="mb-0">
        {localeText("orderId")}: {order.id}
      </p>
      <p className="mb-0">
        {localeText("orderDate")}: {formatDate(order.date)}
      </p>
      <p className="mb-0">
        {localeText("orderTotal")}: {currencyCodeUser} {formatPrice(totalPrice)}
      </p>
      {!productIsDownloadable && (
        <>
          {anyProductIsPickup && !order?.idsendcloudmethod ? (
            <p>
              {localeText("pickUpAddress")}: <br /> {formatAddress(order.seller)}
            </p>
          ) : (
            <p>
              {localeText("deliveryAddress")}: <br /> {formatAddress(order)}
            </p>
          )}
        </>
      )}

      <Button
        label={localeText("goToMyShoppings")}
        className="mx-auto mt-2"
        onClick={handleRedicrectToMyPurchases}
      />
    </div>
  );
};

export default ThankYouPage;
