import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import localeText from "../../../../locale";
import { Link } from "react-router-dom";
import usePlanPrices from "../../hooks/usePlanPrices";

const WorkWithUs = () => {
  const { currency } = usePlanPrices();
  return (
    <div className="bg-green-100">
      <Wrapper id="extracoinSection" classnames="bg-green-100 pt-6 pb-8 text-center">
        <h1 className="text-4xl  lg:text-5xl font-bold text-primary">{localeText("workWithUs")}</h1>
        <p className="text-lg lg:text-2xl text-gray-500">{localeText("jobOpportunity")}</p>
        <p className="text-lg lg:text-xl mx-auto mb-4 lg:mb-5 bg-green-100 w-5">
          {localeText("workWithUsText1")}
        </p>

        <div
          className="pricing-box bg-white border-round-xl mt-4 mx-4 lg:mt-6 mx-auto mb-5 p-4"
          style={{ height: "100%", maxWidth: "500px", width: "90%" }}
        >
          <h3 className="font-bold lg:text-4xl mt-5 text-white bg-green-500 inline-block p-2 border-round mx-auto mb-0 uppercase mb-3">
            Promoter
          </h3>
          <span className="font-bold text-green-600 mx-auto text-color mb-0 block">
            {localeText("physicalPerson")}/{localeText("legalPerson")}
          </span>

          <h3 className="font-bold uppercase lg:text-4xl mt-5 text-primary border-bottom">
            {localeText("freePlan")}
          </h3>
          <div className="w-full bg-green-600 my-6" style={{ height: "2px" }} />
          <ul className="m-0 p-0 px-1">
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-primary font-bold">
                {localeText("instantlyEarnPlanList")}
                <span className="text-green-600">{currency} 500</span> extracredit
              </p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-color font-semibold">
                {localeText("salesRepresentativeContract")}
              </p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-color font-semibold">{localeText("developYourNetwork")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-color font-semibold">{localeText("earnCommissionsAndCredits")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-color font-semibold">{localeText("financialCareerAwards")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
          </ul>
          <Link to="/salepromoter" className="inline-block">
            <button type="button" className="p-button">
              <span className="p-button-text text-xl font-bold uppercase p-1">
                {localeText("joinPlan")}
              </span>
            </button>
          </Link>
        </div>
      </Wrapper>
    </div>
  );
};

export default WorkWithUs;
