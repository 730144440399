import { useEffect, useState } from "react";
import ProductService from "../service/ProductService";
import SearchService from "../service/SearchService";
import { parseProduct } from "../utilities/parsers/product";
import { useLocation } from "react-router-dom";

const useSearchProducts = ({ searchTerm, onSearchError } = {}) => {
  const productService = new ProductService();
  const searchService = new SearchService();

  const url = useLocation();
  const params = new URLSearchParams(url?.search);

  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchingInAll, setSearchingInAll] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchMessage, setSearchMessage] = useState("");

  const [searchParams, setSearchParams] = useState({
    currency: params?.get("currency") ? params?.get("currency") : "ALL",
    type: params?.get("type") ? params?.get("type") : "ALL",
    seller: params?.get("seller") ? params?.get("seller") : "ALL",
    area: params?.get("area") ? params?.get("area") : "ALL",
  });

  const [advancedSearchParams, setAdvancedSearchParams] = useState({
    currency: params?.get("currency") ? params?.get("currency") : "ALL",
    type: params?.get("type") ? params?.get("type") : "ALL",
    seller: params?.get("seller") ? params?.get("seller") : "ALL",
    area: params?.get("area") ? params?.get("area") : "ALL",
  });

  const fetchProducts = async () => {
    if (!searchTerm) return;

    try {
      setLoadingProducts(true);

      const resSearch = await productService.searchByTitle(
        searchTerm,
        searchParams.currency,
        searchParams.type,
        searchParams.seller,
        searchParams.area
      );

      if (resSearch.messageCode !== 200001) {
        onSearchError(resSearch.messageCode);
        setLoadingProducts(false);
      } else {
        setSearchMessage(`${searchTerm}`);
        setSearchingInAll(true);
        setProducts(resSearch.result.map(parseProduct));
        setLoadingProducts(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const search = async ({ expression, onError }) => {
    try {
      if (expression.length > 0) {
        setLoadingSearch(true);

        const usernameSession = sessionStorage.getItem("__extraconomy_username");

        const res = await searchService.postRegisterSearch(
          expression,
          usernameSession ? usernameSession : ""
        );

        if (res.messageCode !== 200000) {
          onError(res.messageCode);
          if (res.messageCode === 500000) console.log(res);
          setLoadingSearch(false);
        } else {
          sessionStorage.setItem("__extraconomy_search", JSON.stringify(res.result));

          if (expression === "ALL")
            window.location.href = `/shop?search=${expression}&currency=${advancedSearchParams.currency}&type=${advancedSearchParams.type}&seller=${advancedSearchParams.seller}&area=${advancedSearchParams.area}`;
          else
            window.location.href = `/shop?search=${expression}&currency=ALL&type=ALL&seller=ALL&area=ALL`;
        }
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        sessionStorage.removeItem("__extraconomy_token");
        sessionStorage.removeItem("__extraconomy_username");
        window.location.replace("/login");
      }
      setLoadingSearch(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchProducts();
    return () => {
      setLoadingProducts(false);
      setProducts([]);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProducts();
    return () => {
      setLoadingProducts(false);
      setProducts([]);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    loadingProducts,
    products,
    searchingInAll,
    loadingSearch,
    searchMessage,
    searchParams,
    advancedSearchParams,
    setAdvancedSearchParams,
    setSearchParams,
    search,
    setProducts,
    setLoadingProducts,
    setSearchMessage,
  };
};

export default useSearchProducts;
