import { Dialog } from "primereact/dialog";
import GetUserInfoService from "../../service/GetUserInfoService";
import { useEffect, useState, useRef } from "react";
import localeText from '../../locale';

import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import SalePromoterService from "../../service/Cadastros/SalePromoterService";

const ConfirmDocument = () => {
  

  const uploadOptions = {
    // label: The label of button.
    // icon: The icon of button.
    className: 'hidden'
    // style:
  }

  const [confirmDocumentVisible, setConfirmDocumentVisible] = useState(false);
  const [documentLocked, setDocumentLocked] = useState(false);
  const [docPic, setDocPic] = useState(null);
  const [docPic2, setDocPic2] = useState(null);
  const [loadingPic, setLoadingPic] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const getUserInfoService = new GetUserInfoService();
  const toast = useRef(null);
  const fileUploadRef = useRef(null);
  const fileUploadRef2 = useRef(null);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      if (sessionStorage.getItem('__extraconomy_token')) {
        try {
          const resInfo = await getUserInfoService.getUserInfo(sessionStorage.getItem('__extraconomy_token'), sessionStorage.getItem('__extraconomy_username'));
          if (cancel) return;

          if (resInfo.messageCode !== 200001) {
            toast.current.show({
              severity: 'error', summary: localeText('error'),
              detail: localeText('data').find(msg => msg.code === resInfo.messageCode).message, life: 6000
            });
          }
          else if (resInfo.result.plan === 'Promoter' && !resInfo.result.user.documentNumberURL) {
            const userObj = resInfo.result;
            const dateCreated = userObj.user.dateCreationUser;

            //Get 1 day in milliseconds
            let one_day = 1000 * 60 * 60 * 24;

            // Convert both dates to milliseconds
            let date1_ms = new Date().getTime();
            let date2_ms = new Date(dateCreated).getTime();

            // Calculate the difference in milliseconds
            let difference_ms = date2_ms - date1_ms;

            // Convert back to days and return
            const daysSinceCreated = Math.round(difference_ms / one_day);

            daysSinceCreated <= -30 ? setDocumentLocked(true) : setDocumentLocked(false);

            setConfirmDocumentVisible(true);
          }
        }
        catch (e) {
          if (e.status === 401 || e.status === 403) {
            sessionStorage.removeItem('__extraconomy_token');
            sessionStorage.removeItem('__extraconomy_username');
            window.location.replace('/login');
          }
          console.log(e);
        }
      }
    }

    fetchData();

    return () => {
      cancel = true;
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const salePromoterService = new SalePromoterService();

  async function uploadDocumentPic() {
    try {
      if (!docPic) {
        toast.current.show({
          severity: 'error', summary: localeText('error'),
          detail: localeText('pleaseUploadDocPic'), life: 6000
        });
        return;
      }

      setLoadingPic(true);

      const res = await salePromoterService.postUploadDocumentPic(sessionStorage.getItem('__extraconomy_token'), docPic,
        sessionStorage.getItem('__extraconomy_username'));

      let resDoc2 = null;
      if (docPic2) {
        resDoc2 = await salePromoterService.postUploadDocumentPic2(sessionStorage.getItem('__extraconomy_token'), docPic2,
          sessionStorage.getItem('__extraconomy_username'))
      }

      if (res.messageCode !== 200002) {
        toast.current.show({
          severity: 'error', summary: localeText('error'),
          detail: localeText('data').find(msg => msg.code === res.messageCode).message, life: 6000
        });
        if (res.messageCode === 500000) console.log(res);
        setLoadingPic(false);
        return false;
      }
      
      if (resDoc2 && resDoc2.messageCode !== 200002) {
        toast.current.show({
          severity: 'error', summary: localeText('error'),
          detail: localeText('data').find(msg => msg.code === resDoc2.messageCode).message, life: 6000
        });
        if (resDoc2.messageCode === 500000) console.log(resDoc2);
        setLoadingPic(false);
        return false;
      }

      toast.current.show({
        severity: 'success', summary: localeText('success'),
        detail: localeText('documentUploadedSuccessfully'), life: 6000
      });
      setDocumentLocked(false);
      setUploaded(true);
      setLoadingPic(false);
    }
    catch (e) {
      if (e.status === 401 || e.status === 403) {
        sessionStorage.removeItem('__extraconomy_token');
        sessionStorage.removeItem('__extraconomy_username');
        window.location.replace('/login');
      }
      setLoadingPic(false);
      console.log(e);
    }
  }

  return (
    <div>
      <Toast ref={toast} />

      <Dialog header={localeText('confirmID')} visible={confirmDocumentVisible} onHide={() => {
        if (!documentLocked)
          setConfirmDocumentVisible(false)
      }}
        breakpoints={{ '960px': '85vw' }} style={{ width: '50vw' }}>
        {uploaded ? (
          <div className="text-center">
            <i className="pi pi-check text-6xl text-600 my-6"></i>
            <p className="font-bold text-2xl mb-6">{localeText('youCanCloseThisWindow')}</p>
          </div>
        ) : (
          <>
            {loadingPic ? (
              <div className="text-center">
                <i className="pi pi-spin pi-spinner text-6xl text-400 my-6"></i>
              </div>
            ) : (
              <>
                {documentLocked ? (<p>{localeText('confirmDocumentPicNow')}</p>) : (<p>{localeText('confirmDocumentPic')}</p>)}

                <p className="mb-2"><b>{localeText('photo')} #1</b> (<span style={{ color: 'red' }}>*</span>{localeText('required')})</p>
                <FileUpload uploadOptions={uploadOptions} chooseLabel={localeText('choose')}
                  cancelLabel={localeText('cancel')} className='mb-4'
                  onSelect={(e) => setDocPic(e.files[0])}
                  onClear={() => setDocPic(null)}
                  onRemove={() => setDocPic(null)}
                  onError={() => setDocPic(null)}
                  onValidationFail={() => setDocPic(null)} accept="image/*" ref={fileUploadRef}
                  emptyTemplate={
                    <div className='text-center'>
                      <i className='pi pi-upload text-400' style={{ fontSize: '3em' }}></i>
                      <h3 className='text-400'>{localeText('dragImgFile')}</h3>
                    </div>
                  } />

                <p className="mb-2"><b>{localeText('photo')} #2</b></p>
                <FileUpload uploadOptions={uploadOptions} chooseLabel={localeText('choose')}
                  cancelLabel={localeText('cancel')} className='mb-4'
                  onSelect={(e) => setDocPic2(e.files[0])}
                  onClear={() => setDocPic2(null)}
                  onRemove={() => setDocPic2(null)}
                  onError={() => setDocPic2(null)}
                  onValidationFail={() => setDocPic2(null)} accept="image/*" ref={fileUploadRef2}
                  emptyTemplate={
                    <div className='text-center'>
                      <i className='pi pi-upload text-400' style={{ fontSize: '3em' }}></i>
                      <h3 className='text-400'>{localeText('dragImgFile')}</h3>
                    </div>
                  } />

                <div className="text-center">
                  <Button label={localeText('uploadDocPic')} icon='pi pi-upload' loading={loadingPic}
                    onClick={() => uploadDocumentPic()} />
                </div>
              </>
            )}
          </>
        )}
      </Dialog>
    </div>
  )
}

export default ConfirmDocument