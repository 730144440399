const payPalCheckoutButtonDictEN = {
  unexpectedErrorTransaction: 'Unexpected error while registering.',
  invalidUserPlan: 'Invalid user plan.',
  emailConfirmationTitle: 'Successfully registered!',
  emailConfirmationMessage: 'Check your email to activate your account.',
  upgradedSuccessfully: 'Account plan updated successfully!',
  upgradedSuccessfullyText: 'You will need to enter your login credentials again. You will be redirected to the login screen in 20 seconds, or you can press the button below.',
  youWillBeRedirectedIn: 'You will be redirected in',
  seconds: 'seconds.',
}

export default payPalCheckoutButtonDictEN