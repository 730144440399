import { AdvancedImage } from "@cloudinary/react";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import { useRef, useState, useMemo, useEffect } from "react";
import ConfirmDocument from "../../components/ConfirmDocument/ConfirmDocument";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import PopupImage from "../../components/PopupImage/PopupImage";
import SEO from "../../components/SEO/SEO";
import AdvancedFilterModal from "../../components/SearchProducts/Components/AdvancedFilterModal/AdvancedFilterModal";
import SearchProducts from "../../components/SearchProducts/SearchProducts";
import Wrapper from "../../components/Wrapper/Wrapper";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import useCategories from "../../hooks/useCategories";
import useGetStoresNames from "../../hooks/useGetStoresNames";
import useSearchProducts from "../../hooks/useSearchProducts";
import { useToggle } from "../../hooks/useToggle";
import localeText from "../../locale";
import { cloudinaryInstance } from "../../utilities/Cloudinary/cloudinaryInstance";
import { locale } from "../../utilities/locale/locale";
import "./Shop.css";
import BreadCrumbShop from "./components/BreadCrumbShop/BreadCrumbShop";
import HeaderSection from "./components/HeaderSection/HeaderSection";
import Products from "./components/Products/Products";
import SectionCategories from "./components/SectionCategories/SectionCategories";
import ShopSections from "./components/ShopSections/ShopSections";
import SubcategoriesFilter from "./components/SubcategoriesFilter/SubcategoriesFilter";
import useShop from "./hooks/useShop";

import { useLocation } from "react-router-dom";
import CustomerBanner from "./components/CustomerBanner/CustomerBanner";

const Shop = ({ search: searchQueryParam }) => {
  const [visible, setVisible] = useState(false);
  const [popupImage, setPopupImage] = useState("");
  const { value, setAsFalse, setAsTrue } = useToggle();
  const {
    value: isVisibleFilterModal,
    setAsFalse: setAsFalseFilterModal,
    setAsTrue: setAsTrueFilterModal,
  } = useToggle();

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const { showErrorToast } = useToastContext();
  const { storeNames, isLoading: isLoadingStoreNames } = useGetStoresNames({
    onError: (code) => showErrorToast({ code }),
  });

  const toast = useRef(null);

  const {
    loadingProducts,
    setLoadingProducts,
    products,
    loadingSearch,
    search,
    setProducts,
    searchMessage,
    setSearchMessage,
    searchParams,
    setSearchParams,
    advancedSearchParams,
    setAdvancedSearchParams,
  } = useSearchProducts({
    searchTerm: searchQueryParam,
  });

  const { categories, loadingCategories } = useCategories({
    onCategoriesError: (code) => showErrorToast({ code }),
  });

  const {
    selectedCategory,
    subcategories,
    selectedSubcategory,
    showPopupImage,
    urlRedirect,
    sectionsProducts,
    setShowPopupImage,
    setSelectedSubcategory,
    setSubcategories,
    setSelectedCategory,
  } = useShop({
    searchTerm: searchQueryParam,
    loadingProducts,
    setLoadingProducts,
    onLoadPopupImage: setPopupImage,
    setProducts,
    onSubCategoriesError: (code) => showErrorToast({ code }),
    onCategoriesError: (code) => showErrorToast({ code }),
  });

  const handleSelectedCategory = (category) => {
    query.delete("category");
    setSearchMessage("");
    setSubcategories(null);
    setSelectedSubcategory(null);
    setSelectedCategory(category);
    setSubcategories(category.subCategories);
  };

  const handleMobileSelectedCategory = (category) => {
    query.delete("category");
    const descriptions = {
      en: category.descriptionEnglish,
      it: category.descriptionItalian,
      pt: category.descriptionPortuguese,
      es: category.descriptionSpanish,
    };
    const description = descriptions[locale]?.toLowerCase() ?? "";
    setSearchMessage("");
    setSubcategories(null);
    setSelectedSubcategory(null);
    setSelectedCategory({ ...category, descriptionCurrentLocale: description });
    setSubcategories(category.subCategories);
  };

  const handleResetMobileCategories = () => {
    setSelectedSubcategory(null);
    setSelectedCategory(null);
    setSubcategories([]);
    setProducts([]);
  };

  useEffect(() => {
    if (query.get("category") && categories.length > 0) {
      const queryCategoryId = query.get("category");
      const currentCategory = categories.find(
        (category) => category.id === Number(queryCategoryId)
      );
      handleMobileSelectedCategory(currentCategory);
    }

    if (query.get("subcategory") && subcategories?.length > 0) {
      const querySubcategoryId = query.get("subcategory");
      const currentSubcategory = subcategories.find(
        (subcategory) => subcategory.id === Number(querySubcategoryId)
      );
      setSelectedSubcategory(currentSubcategory);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories.length, subcategories.length]);

  return (
    <>
      <SEO
        title="Extraconomy"
        image="https://www.extraconomy.com/static/media/logo-white-vertical.14987e887fce75163209.png"
      />
      {showPopupImage && (
        <PopupImage
          urlRedirect={urlRedirect}
          image={popupImage}
          onClick={() => setShowPopupImage(false)}
        />
      )}
      <Toast ref={toast} />
      <Navbar setRightSidebarVisible={setVisible} />
      <ConfirmDocument />

      <AdvancedFilterModal
        setNotVisible={setAsFalseFilterModal}
        visible={isVisibleFilterModal}
        searchParams={advancedSearchParams}
        setSearchParams={setAdvancedSearchParams}
        isLoading={loadingSearch}
        isLoadingStoreNames={isLoadingStoreNames}
        storeNames={storeNames}
        isAdvancedFilter={true}
        filter={() => search({ expression: "ALL", onError: (code) => showErrorToast({ code }) })}
      />
      <SearchProducts
        isLoading={loadingSearch}
        onSearch={(expression) =>
          search({ expression, onError: (code) => showErrorToast({ code }) })
        }
        setAsTrue={setAsTrueFilterModal}
      />

      <SectionCategories
        categories={categories}
        handleSelectedCategory={handleSelectedCategory}
        selectedCategory={selectedCategory}
        loadingCategories={loadingCategories}
      />

      <Sidebar visible={visible} onHide={() => setVisible(false)}>
        <div className="surface-0">
          {loadingCategories ? (
            <div className="h-screen">
              <div className="flex justify-content-center mt-8">
                <i className="pi pi-spin pi-spinner text-6xl text-400"></i>
              </div>
            </div>
          ) : (
            <ul className="list-none m-0 p-0 h-screen">
              {subcategories?.length > 0 ? (
                <>
                  <li className="border-2 border-400 mt-4 mx-2" style={{ borderRadius: "4em" }}>
                    <div className="flex align-items-center justify-content-between">
                      <div className="ml-4">
                        {!selectedCategory?.imageid ? (
                          <div
                            className="bg-cover bg-center border-round h-2rem w-2rem"
                            style={{
                              background: `url(https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=)`,
                            }}
                          ></div>
                        ) : (
                          <AdvancedImage
                            cldImg={cloudinaryInstance
                              .image(selectedCategory.imageid)
                              .format("webp")}
                            className="border-round h-2rem w-2rem"
                          />
                        )}
                      </div>
                      <h6 className="my-4 ml-2">
                        {locale === "en" && selectedCategory?.descriptionEnglish}
                        {locale === "it" && selectedCategory?.descriptionItalian}
                        {locale === "pt" && selectedCategory?.descriptionPortuguese}
                        {locale === "es" && selectedCategory?.descriptionSpanish}
                      </h6>
                      <i
                        className="pi pi-times-circle text-400 text-4xl mr-3 cursor-pointer search-bar-green border-circle"
                        onClick={handleResetMobileCategories}
                      ></i>
                    </div>
                  </li>
                  <div className="mt-4">
                    {subcategories.map((subcategory) => {
                      return (
                        <div key={`sc${subcategory.id}`}>
                          {selectedSubcategory && selectedSubcategory.id === subcategory.id ? (
                            <li
                              className="search-bar-green border-round m-2"
                              style={{
                                background: "#46a748",
                                outline: "2px solid #76e278",
                                color: "white",
                              }}
                            >
                              <div className="flex align-items-center">
                                <div className="ml-4">
                                  {!subcategory.imageid ? (
                                    <div
                                      className="bg-cover bg-center border-round h-2rem w-2rem"
                                      style={{
                                        background: `url(https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=)`,
                                      }}
                                    ></div>
                                  ) : (
                                    <AdvancedImage
                                      cldImg={cloudinaryInstance
                                        .image(subcategory.imageid)
                                        .format("webp")}
                                      className="border-round h-2rem w-2rem"
                                    />
                                  )}
                                </div>
                                <h6 className="ml-4 my-3">
                                  {locale === "en" && subcategory.descriptionEnglish}
                                  {locale === "it" && subcategory.descriptionItalian}
                                  {locale === "pt" && subcategory.descriptionPortuguese}
                                  {locale === "es" && subcategory.descriptionSpanish}
                                </h6>
                              </div>
                            </li>
                          ) : (
                            <li
                              className="search-bar-green border-round"
                              onClick={() => setSelectedSubcategory(subcategory)}
                            >
                              <div className="flex align-items-center">
                                <div className="ml-4">
                                  {!subcategory.imageid ? (
                                    <div
                                      className="bg-cover bg-center border-round h-2rem w-2rem"
                                      style={{
                                        background: `url(https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=)`,
                                      }}
                                    ></div>
                                  ) : (
                                    <AdvancedImage
                                      cldImg={cloudinaryInstance
                                        .image(subcategory.imageid)
                                        .format("webp")}
                                      className="border-round h-2rem w-2rem"
                                    />
                                  )}
                                </div>
                                <h6 className="ml-4 my-3">
                                  {locale === "en" && subcategory.descriptionEnglish}
                                  {locale === "it" && subcategory.descriptionItalian}
                                  {locale === "pt" && subcategory.descriptionPortuguese}
                                  {locale === "es" && subcategory.descriptionSpanish}
                                </h6>
                              </div>
                            </li>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  {categories.map((category) => {
                    return (
                      <li
                        key={`scs${category.id}`}
                        className="search-bar-green border-round"
                        onClick={() => handleMobileSelectedCategory(category)}
                      >
                        <div className="flex align-items-center">
                          <div className="ml-4">
                            {!category.imageid ? (
                              <div
                                className="bg-cover bg-center border-round h-2rem w-2rem"
                                style={{
                                  background: `url(https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=)`,
                                }}
                              ></div>
                            ) : (
                              <AdvancedImage
                                cldImg={cloudinaryInstance.image(category.imageid).format("webp")}
                                className="border-round h-2rem w-2rem"
                              />
                            )}
                          </div>
                          <h6 className="ml-4 my-3">
                            {(locale === "en" || locale === null) && category?.descriptionEnglish}
                            {locale === "it" && category?.descriptionItalian}
                            {locale === "pt" && category?.descriptionPortuguese}
                            {locale === "es" && category?.descriptionSpanish}
                          </h6>
                        </div>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
          )}
        </div>
      </Sidebar>

      <Wrapper>
        <div className="lg:mx-4 mx-2 mb-4">
          <>
            {searchQueryParam && searchMessage && (
              <div className="flex lg:justify-content-between justify-content-center lg:flex-row flex-column mt-5 mb-4">
                <div className="flex justify-content-between align-items-center">
                  <h5 className="m-0">
                    {localeText("searchQueryText")}{" "}
                    <strong>{`"${
                      searchQueryParam.toUpperCase().trim() === "ALL"
                        ? `${localeText("all")}`
                        : searchQueryParam
                    }"`}</strong>
                  </h5>
                  <button
                    type="button"
                    className="p-link lg:hidden p-3 flex justify-content-center align-items-center"
                    onClick={setAsTrue}
                  >
                    <i className="pi pi-filter w-full"></i>
                  </button>
                </div>
                <h5 className="m-0">
                  {products.length}{" "}
                  {products.length === 1 ? localeText("result") : localeText("results")}
                </h5>
              </div>
            )}
            {selectedCategory && (
              <>
                <HeaderSection classNameContainer="lg:mt-6 mt-5">
                  <BreadCrumbShop
                    selectedCategory={selectedCategory}
                    selectedSubcategory={selectedSubcategory ? selectedSubcategory : null}
                    setProducts={setProducts}
                    setSelectedCategory={setSelectedCategory}
                    setSelectedSubcategory={setSelectedSubcategory}
                  />
                  {selectedCategory.descriptionCurrentLocale}
                </HeaderSection>
              </>
            )}
            <div className="flex align-items-start sm:justify-content-start justify-content-center gap-4">
              {selectedCategory && (
                <SubcategoriesFilter
                  subcategories={subcategories}
                  selectedSubcategory={selectedSubcategory}
                  onSelectedSubcategory={setSelectedSubcategory}
                />
              )}
              <Products
                isLoading={loadingProducts}
                products={products}
                searchQueryParam={searchQueryParam}
                searchMessage={searchMessage}
                isSelectedCategory={!!selectedCategory}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                visible={value}
                onHide={setAsFalse}
                isLoadingStoreNames={isLoadingStoreNames}
                storeNames={storeNames}
              />
            </div>
          </>
          <CustomerBanner />
          <ShopSections toast={toast} sectionsProducts={sectionsProducts} />
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Shop;
