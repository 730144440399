const ordersDictES = {
  youDontHave: "No tienes",
  orderDelivered: "Solicitud fue recibida?",
  receiveThisOrder: "Esta solicitud ha sido entregada a la dirección indicada?",
  sellerProfilePic: "Foto de perfil del vendedor",
  buyerProfilePic: "Foto de perfil del comprador",
  itemsQuantity: "Cantidad de artículos",
  orderInfo: "Informaciones de la solicitud",
  backToOrdersList: "Volver a la lista de solicitudes",
  amountCash: "Pago no checkout (PayPal)",
  amountWallet: "Pago con la billetera",
  amountWalletExtracoin: "Pago con Extracredit",
  orderDetails: "Ver detalles",
  sendOrder: "Enviar solicitud",
  receiveOrder: "Informar que la solicitud ha sido recibida",
  confirmOrderWasSent:
    "Por favor confirma que el pedido ha sido enviado, proporcionando los siguientes datos ",
  shippingCompany: "Transportador",
  trackingObject: "ID de seguimiento",
  productReceivedSuccessfully: "Producto recibido con éxito!",
  productSentSuccessfully: "Producto enviado con éxito!",
  paid: "Pago",
  sent: "Enviado",
  received: "Recibido",
  howWasPaid: "Formas de pago",
  receiveDownloadLinks: "Recibir enlaces de descarga",
  receiveDownloadLinksConfirmation:
    'Confirme para descargar sus productos y asigne este pedido como "recibido".',
  pressTheReceiveDownloadLinks:
    'Presione el botón "Recibir enlaces de descarga" a continuación para descargar sus productos',
  download: "Descargar",
  confirmRating: "Confirmar evaluación",
  orderRatedSuccessfully: "¡Pedido evaluado con éxito!",
  rate: "Evaluar",
  rateProduct: "Evaluar producto",
  rateSeller: "Evaluar vendedor",
  comment: "Comentarios a la tienda",
  commentProductService: "Comentarios sobre el producto/servicio",
  optional: "opcional",
  pleaseProvideRating: "Proporcione una evaluación para el producto y el vendedor.",
  rateOrder: "Evaluar Orden",
  labelExplanation:
    "Antes de programar la recogida del paquete, pulsa el botón de abajo para descargar una etiqueta que tienes que imprimir y pegar en tu paquete. ESTE PASO ES OBLIGATORIO, ya que la empresa de transporte solo recogerá su producto si la etiqueta está visible.",
  downloadLabel: "Descargar Etiqueta",
  schedulePackagePickup: "Programar la fecha límite para recoger el paquete",
  shippingInstructions: "Instrucciones de envío",
  schedulePickupTimeFrameWarning:
    "No proporcione fines de semana y días festivos en el marco de tiempo. Poner un plazo holgado, es decir, un plazo de al menos dos días.",
  pickupInfo: "Datos de envío",
  pickupTimeFrame: "Fecha límite para recoger el paquete.",
  shippingMethod: "Método de envío",
  trackingNumber: "Número de rastreo",
  trackingUrl: "URL de seguimiento",
  dateMustNotBeNull: "Proporcione un marco de tiempo para la recogida del paquete.",
  registerPickupConfirmation: "¿Confirmar este plazo de recogida?",
  errorOfSystem: "Error del sistema",
  changeStatusToSent: "Cambiar el estado a enviado",
  confirmOrderWasSent2: "¿Quieres confirmar que el pedido ha sido enviado?",
  productIsYetToBeShipped: "Su producto aún no se ha enviado",
  searchByStoreName: "Buscar por nombre de tienda",
  talkToSeller: "Hablar con el vendedor",
  talkToBuyer: "Hablar con el comprador",
  talkTo: "Hablar con",
  send: "Enviar",
  noMessages: "No hay mensajes",
  cannotExceed250Chars: "Su mensaje no puede exceder los 250 caracteres",
  messageHasPersonalNumber:
    "Su mensaje contiene un número de teléfono personal. Por favor, elimínelo e inténtelo de nuevo.",
  serverError: "Hubo un error del servidor, por favor inténtelo de nuevo más tarde.",
  dowloadYourLabelAndPutInYourProduct:
    "Revisa la etiqueta, coloca tu producto y comprueba si el producto está recogido.",
  readyForPickup: "Listo para recoger",
  sellerIsPreparingYourOrder: "El vendedor está preparando su pedido",
  productWasPickedUp: "Informar que el producto fue recogido",
  youconfirmOrderWasSent: "¿Confirma que retiró este pedido?",
  waitingForBuyerWithdrawal: "Esperando la retirada del comprador",
  productIsReadyToPickup: "Su producto está listo para ser recogido",
};

export default ordersDictES;
