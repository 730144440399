import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function SEO(props) {
  const { title, content, description, image, url, alt } = props;
  useEffect(() => {
    const metaTagList = [
      "[name='og:description']",
      "[property='og:url']",
      "[property='og:type']",
      "[property='og:image:url']",
      "[property='og:image:secure_url']",
      "[property='og:image:type']",
      "[property='og:image:width']",
      "[property='og:image:height']",
      "[property='og:image:alt']",
      "[itemprop='image']",
    ];
    metaTagList.forEach((item) => {
      const tag = document.querySelector(item);
      if (!tag) {
        return;
      }
      tag.remove();
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name={description} content={content} />
        <meta name="og:description" content={content} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:image:url" content={image} />
        <meta property="og:image:secure_url" content={image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content={alt} />
        <meta itemprop="image" content={image} />
        {/* <meta property="fb:app_id" content="818536905907545" /> */}
      </Helmet>
    </>
  );
}

export default SEO;
