import { Button } from "primereact/button";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import GetShareService from "../../service/ShareService";

const ShareButton = ({ productInfo }) => {
  const { showInfoToast } = useToastContext();

  const getShareService = new GetShareService();

  const handleShare = async (event, id, token) => {
    let url = '';

    if(token){
       url = `https://extraconomy.com/product?id=${id}&share=${token}`;
    } else if (sessionStorage.getItem("__extraconomy_token") && sessionStorage.getItem("__extraconomy_username") && !token) {
      const tryagain = await getShareService.getSharePermission(id, sessionStorage.getItem("__extraconomy_token"), sessionStorage.getItem("__extraconomy_username"));

      if( !tryagain?.result?.clicked ) url = `https://extraconomy.com/product?id=${id}&share=${tryagain.result.token}`;
      else url = `https://extraconomy.com/product?id=${id}`;
    } else {
      url = `https://extraconomy.com/product?id=${id}`
    }

    event.stopPropagation();
    event.preventDefault();
    if (navigator.share) {
      await navigator.share({
        url,
      });
    } else {
      navigator.clipboard.writeText(url);
    }
  };

  const handleClick = () => {
    showInfoToast({ summary: "copiedToClipboard" });
  };

  const getShareCreateLink = async (id) => {
    try {
        const res = await getShareService.postShareCreate(id, sessionStorage.getItem("__extraconomy_token"), sessionStorage.getItem("__extraconomy_username"));

        return res.result.token;
    } catch (error) {
      return null;
    }
  }

  return (
    <button
      type="button"
      className="bg-transparent block border-0 m-0 cursor-pointer border-none text-gray-800"
      onClick={async (event) => {
        const token = await getShareCreateLink(productInfo.data.product.id)

        handleShare(event, productInfo.data.product.id, token)
      }}
    >
      {navigator.share ? (
        <i className="pi pi-share-alt md:text-2xl text-xl flex text-gray-700 hover:text-green-600"></i>
      ) : (
        <Button
          icon="pi pi-clone"
          className="p-button-secondary p-button-rounded"
          onClick={handleClick}
        />
      )}
    </button>
  );
};

export default ShareButton;
