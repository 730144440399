const editingProductDictPT = {
  removeImage: "Remover Imagem",
  editProduct: "Editar produto",
  productEditedSuccessfully: "Produto editado com sucesso!",
  thisProductHasVariationsEdit:
    "Este produto tem variações. Você precisa editá-las individualmente para definir o preço, estoque e imagens de variação.",
  clear: "Limpar",
  technicalInfoDeletedSuccessfully: "Informação técnica excluída com sucesso.",
  thumbnailExplanation: "A primeira imagem é a capa do produto",
  productDimensions: "Dimensões do produto",
  dimensionsGreaterThanZero: "As dimensões do produto devem ser maiores que zero.",
  dimensionsGreaterThanOne: "As dimensões do produto devem ser maiores ou iguais a 10.",
  dimensionsJust2DecimalPlaces: "As dimensões do produto devem ter apenas 2 casas decimais.",
  category: "Categoria",
  subcategory: "Subcategoria",
  pleaseUploadDownloadable: "Por favor, faça o upload de um arquivo para o produto.",
  pleaseUploadSample: "Por favor, faça o upload de um arquivo de amostra para o produto.",
  pleaseFillModel: "Por favor, preencha o modelo do produto.",
  actualPrice: "Preço atual",
  newPrice: "Novo preço",
  priceMustBeMoreThanPriceFreight: "O preço deve ser maior que o preço do frete.",
};

export default editingProductDictPT;
