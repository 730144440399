const ordersDictEN = {
  youDontHave: "You don't have",
  orderDelivered: "Order delivered?",
  receiveThisOrder: "Was this order delivered to the address provided?",
  sellerProfilePic: "Seller profile picture",
  buyerProfilePic: "Buyer profile picture",
  itemsQuantity: "Quantity of items",
  orderInfo: "Order info",
  backToOrdersList: "Back to order list",
  amountCash: "Paid in checkout (PayPal)",
  amountWallet: "Paid with wallet",
  amountWalletExtracoin: "Paid in Extracredit",
  orderDetails: "See details",
  sendOrder: "Send order",
  receiveOrder: "Inform order was delivered",
  confirmOrderWasSent: "Please confirm order was sent by providing the following data",
  shippingCompany: "Shipping company",
  trackingObject: "Tracking ID",
  productReceivedSuccessfully: "Product received successfully!",
  productSentSuccessfully: "Product sent successfully!",
  paid: "Paid",
  sent: "Sent",
  received: "Received",
  howWasPaid: "Payment methods",
  receiveDownloadLinks: "Receive download links",
  receiveDownloadLinksConfirmation:
    'Confirm to download your products and assign this order as "received".',
  pressTheReceiveDownloadLinks:
    'Press the "Receive download links" button below to download your products',
  download: "Download",
  confirmRating: "Confirm Rating",
  orderRatedSuccessfully: "Order rated successfully!",
  rate: "Rate",
  rateProduct: "Rate product",
  rateSeller: "Rate seller",
  comment: "Feedback to the store",
  commentProductService: "Feedback about the product/service",
  optional: "optional",
  pleaseProvideRating: "Please provide a rating for the product and the seller.",
  rateOrder: "Rate Order",
  labelExplanation:
    "Before scheduling the collection of the package, please press the button below to download a label that you have to print and stick on your package. THIS STEP IS MANDATORY, as the shipping company will only pickup your product if the label is visible.",
  downloadLabel: "Download Label",
  schedulePackagePickup: "Schedule package pickup time-frame",
  shippingInstructions: "Shipping Instructions",
  schedulePickupTimeFrameWarning:
    "Do not provide weekends and holidays on the time-frame. Put a loose deadline, that is, a period of at least two days.",
  pickupInfo: "Shipping info",
  pickupTimeFrame: "Time-frame for package pickup",
  shippingMethod: "Shipping method",
  trackingNumber: "Tracking number",
  trackingUrl: "Tracking URL",
  dateMustNotBeNull: "Please provide a time-frame for package pickup.",
  registerPickupConfirmation: "Confirm this pickup time-frame?",
  errorOfSystem: "Error of system",
  changeStatusToSent: "Change status to sent",
  confirmOrderWasSent2: "Do you want to confirm that the order has been shipped?",
  productIsYetToBeShipped: "Your product is yet to be shipped",
  searchByStoreName: "Search by store name",
  talkToSeller: "Talk to seller",
  talkToBuyer: "Talk to buyer",
  talkTo: "Talk to",
  send: "Send",
  noMessages: "No messages",
  cannotExceed250Chars: "Your message cannot exceed 250 characters",
  messageHasPersonalNumber:
    "Your message contains a personal phone number. Please remove it and try again.",
  serverError: "There was a server error, please try again later.",
  dowloadYourLabelAndPutInYourProduct:
    "Check the label, place your product and see if the product is collected",
  readyForPickup: "Ready for pickup",
  sellerIsPreparingYourOrder: "The seller is preparing your order",
  productWasPickedUp: "Inform that the product was picked up",
  youconfirmOrderWasSent: "Do you confirm that you picked up this order?",
  waitingForBuyerWithdrawal: "Waiting for buyer withdrawal",
  productIsReadyToPickup: "Your product is ready to be picked up",
};

export default ordersDictEN;
