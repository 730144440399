import axios from 'axios'
import apiURI from './Api';

export default class ProductService {
  async getCategories() {
    const response = await axios.get(`${apiURI}/product/category/all`)
    return response.data;
  }

  async getSubcategories(categoryId) {
    const response = await axios.get(`${apiURI}/product/category/subcategories/getbyidcategory/${categoryId}`)
    return response.data;
  }

  async postUploadImage(token, image) {
    let data = new FormData();
    data.append('image', image);

    const response = await axios({
      method: 'post', url: `${apiURI}/upload/uploadimage`, data: data,
      headers: { 'Authorization': 'Bearer ' + token }
    }).catch(e => console.log(e));

    return response.data
  }

  async postRegisterProduct(token, product) {
    const response = await axios({
      method: 'post', url: `${apiURI}/product/register`, data: product,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async getAttributes(token, username) {
    const response = await axios({ method: 'get', url: `${apiURI}/attribute/getbyuser/${username}`, headers: { 'Authorization': 'Bearer ' + token } });
    return response.data;
  }

  async postAttributeRegister(token, attribute, username) {
    const bodyJson = {
      attribute: attribute,
      username: username
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/attribute/register`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postRegisterAttributeValue(token, attributeId, attributeValue, username) {
    const bodyJson = {
      attributevalue: attributeValue,
      idattribute: attributeId,
      username: username
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/attribute/value/register`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async getAttributeValues(token, attributeId, username) {
    const response = await axios({
      method: 'get', url: `${apiURI}/attribute/value/getbyattributeid/${username}/id/${attributeId}`,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postAssignAttribute(token, attributeId, productId, username) {
    const bodyJson = {
      idattribute: attributeId,
      idproduct: productId,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/assignattribute`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postUnassignAttribute(attributeId, productId) {
    const bodyJson = {
      idattribute: attributeId,
      idproduct: productId,
      user: { username: sessionStorage.getItem("__extraconomy_username") }
    }

    const response = await axios({
      method: 'post',
      url: `${apiURI}/product/unassignattribute`,
      data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("__extraconomy_token") }
    });

    return response.data;
  }

  async getProductById(productId) {
    const response = await axios({
      method: 'get', url: `${apiURI}/product/getproductbyid/${productId}`
    });

    return response.data;
  }

  async postCreateVariations(token, productId, username) {
    const bodyJson = {
      idproduct: productId,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/createvariation`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postSetImageByVariation(token, variationId, imageId, username) {
    const bodyJson = {
      idvariation: variationId,
      imageid: imageId,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/setimagebyvariation`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postSetPriceByVariation(token, variationId, price, username) {
    const bodyJson = {
      idvariation: variationId,
      price: price,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/setpricebyvariation`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postSetStockByVariation(token, variationId, productId, stock, username) {
    const bodyJson = {
      amountInStock: stock,
      idproduct: productId,
      idvariation: variationId,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/setamountinstock/setbyvariation`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postSetStockByProduct(token, productId, stock, username) {
    const bodyJson = {
      amountInStock: stock,
      idproduct: productId,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/setamountinstock/setbyproduct`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async deleteVariation(token, variationId, username) {
    const response = await axios({
      method: 'delete', url: `${apiURI}/product/deletevariation/${variationId}/user/${username}`,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async searchBySubcategory(subcategoryId) {
    const response = await axios.get(`${apiURI}/product/search/bysubcategory/${subcategoryId}`);
    return response.data;
  }

  async searchByCategory(categoryId) {
    const response = await axios.get(`${apiURI}/product/search/bycategory/${categoryId}`);
    return response.data;
  }

  async searchByTitleAndSubcategory(expression, subcategoryId) {
    const response = await axios.get(`${apiURI}/product/search/bytitle/${expression}/subcategory/${subcategoryId}`);
    return response.data;
  }

  async searchByTitle(expression, currency, type, seller, area) {
    const response = await axios.get(`${apiURI}/product/search/bytitle/${expression ?? "ALL"}/currency/${currency ?? "ALL"}/type/${type ?? "ALL"}/seller/${seller ?? "ALL"}/area/${area ?? "ALL"}`);
    return response.data;
  }

  async postModifyStatus(token, productId, status, username) {
    const bodyJson = {
      idproduct: productId,
      productStatus: status,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/modifystatus`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async getAllProducts() {
    const response = await axios.get(`${apiURI}/product/getall`);
    return response.data;
  }

  async postEditProduct(token, username, title, description, percentExtracoin, imageid1, imageid2,
    imageid3, imageid4, imageid5, productId, weight, width, height, length, subcategoryId, brand = null,
    type = null, genderProduct = null, model = null, service, downloadable, downloadType, cdnType, urldownload, urldownloadsample, shippingOption = null, conditionsUsedProduct, productState) {
    const bodyJson = {
      title: title,
      desctiption: description,
      imageid1: imageid1,
      imageid2: imageid2,
      imageid3: imageid3,
      imageid4: imageid4,
      imageid5: imageid5,
      percentExtracoin: percentExtracoin,
      idproduct: productId,
      idsubcategory: subcategoryId,
      user: { username: username },
      weight: weight,
      width: width,
      height: height,
      length: length,
      idbrand: brand,
      idtype: type,
      idgender: genderProduct,
      model: model,
      service: service,
      downloadable: downloadable,
      downloadType: downloadType,
      cdnType: cdnType,
      urldownload: urldownload,
      urldownloadsample: urldownloadsample,
      shippingOption: shippingOption,
      conditionsUsedProduct,
      productState
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/edit`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postSetPriceByProduct(token, username, price, pricePromotional, productId) {
    const bodyJson = {
      idproduct: productId,
      pricepromotional: pricePromotional,
      price: price,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/setpricebyproduct`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postUploadFile(token, file) {
    let data = new FormData();
    data.append('image', file);

    const response = await axios({
      method: 'post', url: `${apiURI}/upload/uploadfile`, data: data,
      headers: { 'Authorization': 'Bearer ' + token }
    }).catch(e => console.log(e));

    return response.data;
  }

  async postUploadAudio(token, audio) {
    let data = new FormData();
    data.append('file', audio);

    const response = await axios({
      method: 'post', url: `${apiURI}/upload/uploadaudio`, data: data,
      headers: { 'Authorization': 'Bearer ' + token }
    }).catch(e => console.log(e));

    return response.data;
  }

  async getProductRating(productId) {
    const response = await axios({
      method: 'get', url: `${apiURI}/product/getrate/${productId}`,
    })

    return response.data;
  }

  async getRelatedProducts(productId) {
    const response = await axios({
      method: 'get', url: `${apiURI}/product/relatedproducts/${productId}`,
    })

    return response.data;
  }

  async postRegisterTechnicalInfo(token, productId, key, value, username) {
    const bodyJson = {
      idproduct: productId,
      key: key,
      value: value,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/technicalinfo/register`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async getTechnicalInfo(productId) {
    const response = await axios({
      method: 'get', url: `${apiURI}/product/technicalinfo/get/${productId}`,
    })

    return response.data;
  }

  async getQuestionsOfProduct(productId) {
    const response = await axios({
      method: 'get', url: `${apiURI}/product/questions/list/${productId}`,
    })

    return response.data;
  }

  async postAsk({ idproduct, question, username }) {
    const bodyJson = {
      idproduct,
      username,
      question
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/questions/ask`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("__extraconomy_token") }
    });

    return response.data;
  }

  async postAnswer(answer, idproduct, idquestion, username) {
    const bodyJson = {
      answer,
      idproduct,
      idquestion,
      username,
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/questions/answer`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("__extraconomy_token") }
    });

    return response.data;
  }

  async getCommentsOfProduct(productId) {
    const response = await axios({
      method: 'get', url: `${apiURI}/product/getcomments/${productId}`,
    })

    return response.data;
  }

  async getCommentsOfStore(username) {
    const response = await axios({
      method: 'get', url: `${apiURI}/user/getcomments/${username}`,
    })

    return response.data;
  }

  async deleteTechnicalInfo(token, techInfoId, username) {
    const response = await axios({
      method: 'delete', url: `${apiURI}/product/technicalinfo/delete/${techInfoId}/user/${username}`,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postEditTechnicalInfo(token, techInfoId, key, value, username) {
    const bodyJson = {
      idtechnicalinfo: techInfoId,
      key: key,
      value: value,
      user: { username: username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/product/technicalinfo/edit`, data: bodyJson,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }
}
