const LandingVideo = ({ links, locale }) => {
  const iframeLinks = {
    en: links.en,
    it: links.it,
    pt: links.pt,
  };
  const currentIframeLink = iframeLinks[locale];
  //const secondVideo = "https://www.youtube-nocookie.com/embed/zu_QR13RnC8";

  if (!currentIframeLink) return null;
  return (
    <div className="flex justify-content-center align-items-center gap-3 flex-wrap">
      <iframe
        width="560"
        height="315"
        src={links[locale]}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="max-w-full"
      ></iframe>
      {/* <iframe
        width="560"
        height="315"
        src={secondVideo}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="max-w-full"
      ></iframe> */}
    </div>
  );
};

export default LandingVideo;
