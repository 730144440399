import { useState } from "react";
import { useToastContext } from "../../../../../contexts/ToastContext/ToastContext";
import useApiStatus from "../../../../../hooks/useApiStatus";
import ProductService from "../../../../../service/ProductService";
import { hasPhoneNumber } from "../../../../../utilities/hasPhoneNumber";
import { useHistory, useLocation } from "react-router-dom";

const useReplyQuestion = ({ idProduct, setAsFalse, setQuestions, questions }) => {
  const [currentQuestion, setCurrentQuestion] = useState(); // eslint-disable-line no-unused-vars
  const { isLoading, setIsLoading } = useApiStatus();
  const [answer, setAnswer] = useState("");
  const history = useHistory();
  const url = useLocation();
  const params = new URLSearchParams(url?.search);

  const { showErrorToast, showSuccessToast } = useToastContext();

  const productService = new ProductService();

  const postAnswer = async () => {
    if (!answer || answer === "") {
      showErrorToast({ detail: "answerQuestionEmpty" });
      return;
    }
    if (answer.length > 200) {
      showErrorToast({ detail: "answerQuestionTooLong" });
      return;
    }
    setIsLoading(true);
    try {
      const username = sessionStorage.getItem("__extraconomy_username");

      if (username) {
        if (hasPhoneNumber(answer)) {
          showErrorToast({ detail: "answerQuestionHasPhoneNumber" });
          return;
        }

        const resPostAsk = await productService.postAnswer(
          answer,
          idProduct,
          currentQuestion.id,
          username
        );

        if (resPostAsk.messageCode !== 200000) {
          showErrorToast({ code: resPostAsk.messageCode });
          setIsLoading(false);
        } else {
          const newQuestions = questions.map((question) => {
            if (question.id === currentQuestion.id) {
              return {
                ...question,
                answer: resPostAsk?.result?.answer,
                dateAnswer: resPostAsk?.result?.dateAnswer,
                productOwnerFirstName: resPostAsk?.result?.product?.user?.firstname,
              };
            }
            return question;
          });
          setQuestions(newQuestions);
          showSuccessToast({ detail: "answerQuestionSuccess" });
          setAsFalse();
          setIsLoading(false);
          setAnswer("");
          params.delete("questionId");
          history.push(`${url.pathname}?${params?.toString()}`);
        }
      }
    } catch (e) {
      setIsLoading(false);
      showErrorToast({ code: 500000 });
      console.log(e);
    }
  };

  return {
    answer,
    setAnswer,
    isLoading,
    postAnswer,
    setCurrentQuestion,
    currentQuestion,
  };
};

export default useReplyQuestion;
