import MockupPortuguese from "../images/landing/MockupPortuguês.png";
import MockupEnglish from "../images/landing/MockupInglês.png";
import MockupSpanish from "../images/landing/MockupEspanhol.png";
import MockupItalian from "../images/landing/MockupItaliano.png";
import howItWorksIT from "../../assets/static/comofuncionaIT.pdf";

export const features = [
  {
    icon: "pi pi-wallet",
    title: "extracredit",
    desc: "extracoinExplanation",
  },
  {
    icon: "pi pi-users",
    title: "indicate",
    desc: "youMayGain",
  },
  {
    icon: "pi pi-chart-pie",
    title: "economicNetwork",
    desc: "networkOfUsers",
  },
  {
    icon: "pi pi-shield",
    title: "transactionSafety",
    desc: "ourPlatform",
  },
  {
    icon: "pi pi-search",
    title: "findEverything",
    desc: "weHavePowerfulTool",
  },
  {
    icon: "pi pi-comments",
    title: "buyerReviews",
    desc: "checkTheReviews",
  },
  {
    icon: "pi pi-globe",
    title: "geolocation",
    desc: "effectiveTools",
  },
  {
    icon: "pi pi-money-bill",
    title: "exceptionalTrading",
    desc: "saferMoreFrequent",
  },
  {
    icon: "pi pi-dollar",
    title: "economicInfluence",
    desc: "sellFaster",
  },
];

export const linkVideos = {
  pt: "https://www.youtube-nocookie.com/embed/ZrcBc2VH750",
  en: "https://www.youtube-nocookie.com/embed/rF1C5_OHKuA",
  it: "https://www.youtube.com/embed/pnybQiMS4xA?si=-Ns2V2uahG4NwxRu",
};

export const linksButtonHowItWorks = {
  pt: "https://drive.google.com/file/d/1NQWQyJTs40mw28wJbP_6KLsa1b6JECTb/view?usp=sharing",
  es: "https://drive.google.com/file/d/1XNEgrIySpHzluzMgLwRte7DdkJPAGHZJ/view?usp=sharing",
  it: howItWorksIT,
};

export const mockup = {
  en: MockupEnglish,
  it: MockupItalian,
  pt: MockupPortuguese,
  es: MockupSpanish,
};
