import { useEffect, useState } from "react";
import errorImg from "../images/pages/bg-error.jpg";
import logoBlack from "../images/logo-black-horizontal.png";
import "./EmailConfirmation.css";
import { Link } from "react-router-dom";
import localeText from "../../locale";

const isValidURL = (str) => {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
};

const EmailConfirmation = ({ title, message, type, url = "" }) => {
  const [typeText, setTypeText] = useState("");
  console.log(title, message, type, url);

  useEffect(() => {
    if (type === "error") {
      document.getElementById("exception-image").style.background = "url(" + errorImg + ")";
      document.getElementById("exception-image").style.backgroundSize = "cover";
      setTypeText(localeText("error").toUpperCase());
    } else if (type === "success") {
      document.getElementById("exception-image").style.background =
        "linear-gradient(#46a748, #272360)";
      document.getElementById("exception-image").style.backgroundSize = "cover";
      setTypeText(localeText("success").toUpperCase());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="exception-image" className="exception-body">
      <div className="exception-panel"></div>
      <div className="exception-content">
        <div className="card ml-4 mr-4 pb-8 pt-8 msg-card-responsive">
          <Link to="/">
            <img src={logoBlack} alt="babylon-layout" style={{ width: "270px" }} />
          </Link>
          <h1 className="text-700 pb-4">{typeText}</h1>
          <h3 className="text-900">{title}</h3>
          <p>{message}</p>
          {isValidURL(url) && (
            <div>
              <a
                href={url}
                className="p-button"
                style={{ padding: "0.5rem 1rem", marginBottom: "16px" }}
                target="_blank"
                rel="noopener noreferrer"
                download={true}
              >
                {localeText("download")}
              </a>
            </div>
          )}
          <a
            href="mailto:support@extraconomyglobal.com"
            target="_blank"
            rel="noreferrer"
            style={{ marginBottom: "16px", display: "block" }}
          >
            support@extraconomyglobal.com
          </a>
          <Link to="/">{localeText("backToHomePage")}</Link>
        </div>
        <div className="msg-card mt-8">
          <Link to="/">
            <img src={logoBlack} alt="babylon-layout" style={{ width: "270px" }} />
          </Link>
          <h1 className="text-700 pb-4">{typeText}</h1>
          <h3 className="text-900">{title}</h3>
          <p>{message}</p>
          {isValidURL(url) && (
            <div>
              <a
                href={url}
                className="p-button"
                style={{ padding: "0.5rem 1rem", marginBottom: "14px" }}
                target="_blank"
                rel="noopener noreferrer"
                download={true}
              >
                {localeText("download")}
              </a>
            </div>
          )}
          <p style={{ fontSize: "14px", marginBottom: "0" }}>{localeText("emailCanBeSpam")}</p>

          <p style={{ marginBottom: "16px", fontSize: "14px" }}>
            {localeText("doubts")}{" "}
            <a href="mailto:support@extraconomyglobal.com" target="_blank" rel="noreferrer">
              support@extraconomyglobal.com
            </a>
          </p>

          <Link to="/">{localeText("backToHomePage")}</Link>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
