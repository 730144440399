export const footerLinks = [
    // {
    //     href: "https://instagram.com/extraconomyit?igshid=OGQ5ZDc2ODk2ZA==",
    //     icon: "pi-instagram",
    // },
    {
        href: "https://www.facebook.com/extraconomy.global",
        icon: "pi-facebook",
    },
    {
        href: "https://wa.me/message/AALEU6LSOPNWM1",
        icon: "pi-whatsapp",
    },
    {
        href: "https://t.me/+ioCTpVD9LmhhZTc0",
        icon: "pi-telegram",
    },
];
