const withdrawIntentDictES = {
  paypalEmail: "Ingrese el correo electrónico del PayPal a continuación",
  amountWithdrawn: "Valor retirado de la billetera",
  amountDeposited: "Valor depositado en el PayPal",
  withdrawFee: "tarifa de retiro",
  requestWithdraw: "Solicitar retiro",
  amountWithdrawnSuccessfully: "Valor retirado con éxito!",
  invalidemail: "Correo electrónico inválido",
  withdrawRequests: "Solicitudes de retiro",
  requestDate: "Fecha de solicitud",
  processDate: "Fecha de procesamiento",
  processing: "Procesando",
  amountToDeposit: "La cantidad a depositar",
  amountRequested: "Monto requerido",
  processRequest: "Procesa solicitud",
  process: "Proceso",
  withdrawProcessedSuccessfully: "¡Solicitud de retiro procesada con éxito!",
  askFinancialPassword: "Ingrese su contraseña financiera",
  financialPasswordIsWrong: "Contraseña financiera incorrecta",
  withdrawWith: "Retirar con",
  bank: "Banco",
  bankName: "Nombre del banco",
  bankAccountNumber: "Número de cuenta bancaria",
  accountOwner: "Nombre del titular de la cuenta",
  allFieldsRequired: "Todos los campos son obligatorios",
};

export default withdrawIntentDictES;
