const editingProductDictEN = {
  removeImage: "Remove Image",
  editProduct: "Edit product",
  productEditedSuccessfully: "Successfully edited product!",
  thisProductHasVariationsEdit:
    "This product has variations. You have to edit them individually to set their price, stock, and variation images.",
  clear: "Clear",
  technicalInfoDeletedSuccessfully: "Technical info deleted successfully.",
  thumbnailExplanation: "The first image is the product thumbnail",
  productDimensions: "Product dimensions",
  dimensionsGreaterThanZero: "Product dimensions have to be greater than zero.",
  dimensionsGreaterThanOne: "Product dimensions must be greater than or equal to 10.",
  dimensionsJust2DecimalPlaces: "Product dimensions have to have just 2 decimal places.",
  category: "Category",
  subcategory: "Subcategory",
  pleaseUploadDownloadable: "Please upload a file for the product.",
  pleaseUploadSample: "Please upload a sample file for the product.",
  pleaseFillModel: "Please fill the product model.",
  actualPrice: "Actual price",
  newPrice: "New price",
  priceMustBeMoreThanPriceFreight: "The price must be greater than the freight price.",
};

export default editingProductDictEN;
