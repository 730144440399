import { useEffect, useState } from "react";
import ProductService from "../service/ProductService";

const useCategories = ({ onCategoriesError } = {}) => {
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [categories, setCategories] = useState([]);

  const productService = new ProductService();

  const fetchCategories = async () => {
    setLoadingCategories(true);
    try {
      const resCategories = await productService.getCategories();

      if (resCategories.messageCode !== 200001) {
        onCategoriesError(resCategories.messageCode);
      } else {
        setCategories(resCategories.result);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    fetchCategories();

    return () => {
      setLoadingCategories(false);
      setCategories([]);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingCategories,
    categories,
  };
};

export default useCategories;
