import { CartLogo } from "../svg/cart";
import useButtonAddToCart from "../../../hooks/useButtonAddToCart";
import { useToastContext } from "../../../../../contexts/ToastContext/ToastContext";

export const MiniAddToCart = ({
  productInfo,
  currentVariation,
  currentAttribute1Value,
  currentAttribute2Value,
  productQty,
}) => {
  const { showErrorToast } = useToastContext();
  const { addToCart, loadingOrder } = useButtonAddToCart({
    currentVariation: currentVariation,
    currentAttribute1Value,
    currentAttribute2Value,
    productQty,
    productInfo,
    onErrorAddToCart: (code) => showErrorToast({ code }),
    onErrorDownloadableAndNonDownloadable: () =>
      showErrorToast({
        detail: "downloadableAndNonDownloadable",
      }),
    onErrorData: (code) => showErrorToast({ code }),
    onErrorItemAlreadyInCart: () => showErrorToast({ detail: "itemAlreadyInCart" }),
    onErrorOneSeller: () => showErrorToast({ detail: "onlyOneSeller" }),
  });

  const { product } = productInfo.data;

  const isDisabled = loadingOrder
    ? "opacity-80 pointer-events-none cursor-pointer flex align-items-center justify-content-center sm:p-2 p-1 border-green-400 sm:border-3 border-2 border-solid border-round-md"
    : "cursor-pointer flex align-items-center justify-content-center sm:p-2 p-1 border-green-400 border-2 border-solid border-round-md hover:bg-gray-100";
  const buttonClass = `${isDisabled}`;

  return (
    <div
      className={buttonClass}
      onClick={() => {
        if (product?.user?.username === sessionStorage.getItem("__extraconomy_username"))
          return showErrorToast({ detail: "errorThisProductIsYours" });
        addToCart();
      }}
    >
      <CartLogo />
    </div>
  );
};
