const appTopbarDictIT = {
  welcome: "Benvenuto",
  editProfile: "Modifica Profilo",
  logout: "Disconnettersi",
  cancelAccount: "Annulla Account",
  writeCancelToConfirm: 'Scrivi "CANCELLARE" per confermare',
  confirmYourPassword: "Conferma la tua password",
  accountCanceledSuccessfully: "Account cancellato con successo! Sarai disconnesso a breve.",
  changePassword: "Cambiare la Password",
  currentPassword: "Password attuale",
  newPassword: "Nuova password",
  passwordChangedSuccessfully: "Password aggiornata correttamente!",
  upgradeAccount: "Aggiornare l'account",
  upgradeTo: "L'aggiornamento a",
  learnMoreAboutThePlan: "Saperne di più sul piano",
  upgradeWithVoucher: "Piano di upgrade con voucher",
  including: "inclusa",
};

export default appTopbarDictIT;

