const shoppingCartDictPT = {
  productType: "Tipo do produto:",
  soldBy: "Vendido por:",
  pickUpInStore: "Retirar na loja",
  orderResume: "Resumo do Pedido",
  shippingOptions: "Opções de envio",
  checkCatalog: "Conferir nosso catálogo",
  emptyCartText: "Seu carrinho de compras está vazio.",
  emptyCartSuggestion:
    "Adicione itens ao carrinho e aproveite o marketplace que te recompensa sempre.",
  percentualCreditInfo: "Informações de crédito percentual",
  shop: "Loja",
  searchProducts: "Buscar produtos...",
  searchByTitleInThisSubcategory: "Pesquise nessa subcategoria...",
  byCap: "Por",
  product: "Produto",
  noResultsFound: "Nenhum resultado encontrado.",
  shoppingCart: "Carrinho de compras",
  cart: "Carrinho",
  subtotal: "Subtotal",
  onlyOneSeller: "Você só pode comprar produtos de um vendedor.",
  items: "itens",
  platformFee: "Taxa da plataforma",
  total: "Total",
  walletBalance: "Saldo atual da sua carteira",
  amountToPayWithWalletBalance: "Pague com o saldo da sua carteira",
  amountToPayWithExtracredit: "Pague com Extracredit",
  finishOrder: "Fechar pedido",
  searching: "Pesquisando",
  inSubcategory: "na subcategoria",
  inAllProducts: "em todos os produtos",
  productMaximumExtracoinPercent: "Porcentagem máxima de Extracredit (Produto)",
  userMaximumExtracoinPercent: "Porcentagem máxima de Extracredit (Usuário)",
  checkout: "Checkout",
  amountToPayAtCheckout: "Valor a pagar no checkout",
  orderData: "Dados do pedido",
  howWillPay: "Como vai pagar",
  otherMethods: "Outras formas",
  creditRequested: "Crédito solicitado",
  moreDetails: "mais detalhes",
  productNotFound: "Produto não encontrado. Tente pesquisar em nosso catálogo.",
  downloadableAndNonDownloadable:
    "Produtos baixáveis e não-baixáveis não podem estar no mesmo carrinho.",
  balanceAvailable: "Saldo Extracredit disponível",
  freeExtracoinExplanation1: "Como um",
  freeExtracoinExplanation2:
    "recém-registrado, você receberá um pagamento completo de Extracredit gratuitamente em seus três primeiros pedidos, o que significa que você pagará o valor mínimo da compra sem gastar seus créditos Extracredit.",
  itemFavorited: "Item adicionado aos favoritos.",
  itemRemovedFromFavorites: "Item removido dos favoritos.",
  info: "Info",
  searchForShipping: "Pesquisar opções de envio para este endereço de correspondência",
  shippingQuotesFor: "Cotações de envio para",
  pleasePickASendMethod:
    'Clique no botão "Opções de envio para este endereço de correspondência" e depois selecione um método de envio',
  shippingCost: "Frete",
  searchAddress: "Auto preencher com CEP",
  shippingResponsibilitySeller: "O envio é de responsabilidade do vendedor",
  plataformFeeExplanation: "A taxa da plataforma é de 5% do valor total dos produtos.",
  cashbackExplanation:
    "Qualquer valor que não for pago em extracredit terá 50% dele convertido em cashback, retornando para a sua carteira em extracredit.",
  thankYouForYourPurchase: "Obrigado por sua compra",
  pleaseFillAllFieldsAddressOrSearchShippingMethod:
    "Por favor, preencha todos os campos do endereço ou clique em opções de envio.",
  deliveryAddress: "Endereço de entrega",
  pickUpAddress: "Retirar seu pedido em",
  orderId: "ID do pedido",
  orderDate: "Data do pedido",
  orderTotal: "Total do pedido",
  extraconomyExplain:
    "Você tem a flexibilidade de utilizar seu saldo Extracredit e/ou o saldo da carteira para pagar seu pedido. Escolha abaixo quanto deseja usar de cada um para completar sua compra.",
  orderInformation: "Informações do pedido",
  goToMyShoppings: "Ir para minhas compras",
  errorDontHavePhoneNumber:
    "Você não tem um número de telefone cadastrado. Preencha seu número de telefone e Código de Telefone do País para continuar.",
  insufficientFunds: "saldo insuficiente",
  errorCheckout:
    "Houve um erro ao finalizar seu pedido. Por favor, entre em contato com o suporte pelo email a seguir. E não se esqueça de informar as informações abaixo para ele.",
  contactEmail: "Entrar em contato"
};

export default shoppingCartDictPT;
