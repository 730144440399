const shoppingCartDictIT = {
  productType: "Tipologia di prodotto:",
  soldBy: "Venduto da:",
  pickUpInStore: "Ritira in negozio", // ritirare in negozio
  orderResume: "Riepilogo dell'ordine", // Riepilogo ordine
  shippingOptions: "Opzioni di spedizione",
  checkCatalog: "Controlla il nostro catalogo",
  emptyCartText: "Il tuo carrello della spesa è vuoto.",
  emptyCartSuggestion:
    "Aggiungi articoli al carrello e approfitta del marketplace che ti ricompensa sempre.",
  percentualCreditInfo: "Informazioni sul credito percentuale",
  shop: "Negozio",
  searchProducts: "Cercare prodotti...",
  searchByTitleInThisSubcategory: "Cerca in questa sottocategoria...",
  byCap: "Di",
  product: "Prodotto",
  noResultsFound: "Nessun risultato trovato.",
  shoppingCart: "Carrello della spesa",
  cart: "Carrello",
  subtotal: "Totale provvisorio",
  onlyOneSeller: "Puoi acquistare solo prodotti da un venditore.",
  items: "articoli",
  platformFee: "Tariffa di piattaforma",
  total: "Totale",
  walletBalance: "Saldo attuale del tuo portafoglio",
  amountToPayWithWalletBalance: "Paga con il saldo del tuo portafoglio",
  amountToPayWithExtracredit: "Paga con Extracredit",
  finishOrder: "Finire l'ordine",
  searching: "Ricerca",
  inSubcategory: "nella sottocategoria",
  inAllProducts: "in tutti i prodotti",
  productMaximumExtracoinPercent: "Percentuale massima di Extracredit (Prodotto)",
  userMaximumExtracoinPercent: "Percentuale massima di Extracredit (Utente)",
  checkout: "Memorizzare",
  amountToPayAtCheckout: "Valore da pagare alla cassa",
  orderData: "Dati di acquisto",
  howWillPay: "Come pagherai",
  otherMethods: "Altri metodi",
  creditRequested: "Credito richiesto",
  moreDetails: "più dettagli",
  productNotFound: "Prodotto non trovato. Prova a cercare nel nostro catalogo.",
  downloadableAndNonDownloadable:
    "I prodotti scaricabili e non scaricabili non possono trovarsi nello stesso carrello.",
  balanceAvailable: "Saldo Extracredit disponibile",
  freeExtracoinExplanation1: "Come",
  freeExtracoinExplanation2:
    "appena registrato, ti verrà concesso un pagamento completo di Extracredit gratuitamente nei tuoi primi tre ordini, il che significa che pagherai l'importo minimo di acquisto senza spendere i tuoi crediti Extracredit.",
  itemFavorited: "Articolo aggiunto ai preferiti.",
  itemRemovedFromFavorites: "Articolo rimosso dai preferiti.",
  info: "Info",
  searchForShipping: "Cerca le opzioni di spedizione per questo indirizzo postale",
  shippingQuotesFor: "Preventivi di spedizione per",
  pleasePickASendMethod:
    'Fai clic sul pulsante "Opzioni di spedizione" e seleziona un metodo di spedizione',
  shippingCost: "Spese di spedizione",
  searchAddress: "Auto Compila con CAP",
  shippingResponsibilitySeller: "La spedizione è responsabilità del venditore",
  plataformFeeExplanation: "La tariffa di piattaforma è del 5% del valore totale dei prodotti.",
  cashbackExplanation:
    "Qualsiasi importo non pagato in extracredit avrà il 50% convertito in cashback, tornando al tuo portafoglio in extracredit.",
  thankYouForYourPurchase: "Grazie per il tuo acquisto",
  pleaseFillAllFieldsAddressOrSearchShippingMethod:
    "Compila tutti i campi dell'indirizzo o fai clic su Opzioni di spedizione.",
  deliveryAddress: "Indirizzo di consegna",
  pickUpAddress: "Ritira il tuo ordine presso",
  orderId: "ID ordine",
  orderDate: "Data ordine",
  orderTotal: "Totale ordine",
  extraconomyExplain:
    "Hai la flessibilità di utilizzare il saldo Extracredit e/o il saldo del portafoglio per pagare il tuo ordine. Scegli qui sotto quanto desideri utilizzare da ciascuno per completare il tuo acquisto.",
  orderInformation: "Informazioni ordine",
  goToMyShoppings: "Vai ai miei acquisti",
  errorDontHavePhoneNumber:
    "Non hai un numero di telefono registrato. Inserisci il tuo numero di telefono e il codice telefonico nazionale per continuare.",
  insufficientFunds: "fondi insufficienti",
  errorCheckout:
    "Si è verificato un errore durante il completamento dell'ordine. Si prega di contattare l'assistenza tramite l'e-mail seguente. E non dimenticare di fornirgli le informazioni di seguito.",
  contactEmail: "Contattaci",
};

export default shoppingCartDictIT;
