const ordersDictIT = {
  youDontHave: "Non hai",
  orderDelivered: "Ordine Ricevuto?",
  receiveThisOrder: "Questo ordine è stato consegnato all'indirizzo fornito?",
  sellerProfilePic: "Immagine del profilo del venditore",
  buyerProfilePic: "Immagine del profilo dell'acquirente",
  itemsQuantity: "Quantità di articoli",
  orderInfo: "Informazioni sull'ordine",
  backToOrdersList: "Torna all'elenco degli ordini",
  amountCash: "Pagato alla cassa (PayPal)",
  amountWallet: "Pagato con portafoglio",
  amountWalletExtracoin: "Pagato in Extracredit",
  orderDetails: "Vedi i dettagli",
  sendOrder: "Invia ordine",
  receiveOrder: "Informa che l'ordine è stato ricevuto",
  confirmOrderWasSent:
    "Si prega di confermare che l'ordine è stato inviato fornendo i seguenti dati",
  shippingCompany: "Compagnia di spedizione",
  trackingObject: "ID di tracciamento",
  productReceivedSuccessfully: "Prodotto ricevuto con successo!",
  productSentSuccessfully: "Prodotto inviato con successo!",
  paid: "a pagamento",
  sent: "Inviato",
  received: "Ricevuto",
  howWasPaid: "Modalità di pagamento",
  receiveDownloadLinks: "Ricevi link per il download",
  receiveDownloadLinksConfirmation:
    'Conferma per scaricare i tuoi prodotti e assegnare questo ordine come "ricevuto".',
  pressTheReceiveDownloadLinks:
    'Premi il pulsante "Ricevi link per il download" qui sotto per scaricare i tuoi prodotti',
  download: "Download",
  confirmRating: "Conferma valutazione",
  orderRatedSuccessfully: "Ordine valutato con successo!",
  rate: "Valutazione",
  rateProduct: "Valuta prodotto",
  rateSeller: "Valuta il venditore",
  comment: "Feedback al negozio",
  commentProductService: "Feedback sul prodotto/servizio",
  optional: "opzionale",
  pleaseProvideRating: "Si prega di fornire una valutazione per il prodotto e il venditore.",
  rateOrder: "Valuta L'ordine",
  labelExplanation:
    "Prima di programmare il ritiro del pacco, premi il pulsante sottostante per scaricare un'etichetta da stampare e incollare sul pacco. QUESTO PASSO È OBBLIGATORIO, in quanto lo spedizioniere ritirerà il tuo prodotto solo se l'etichetta è visibile.",
  downloadLabel: "Scarica l'etichetta",
  schedulePackagePickup: "Pianifica la scadenza per il ritiro del pacco",
  shippingInstructions: "Istruzioni di spedizione",
  schedulePickupTimeFrameWarning:
    "Non fornire fine settimana e festività nell'intervallo di tempo. Metti una scadenza libera, cioè un periodo di almeno due giorni.",
  pickupInfo: "Informazioni di spedizione",
  pickupTimeFrame: "Scadenza per il ritiro del pacco",
  shippingMethod: "Metodo di spedizione",
  trackingNumber: "Numero di identificazione",
  trackingUrl: "URL di tracciamento",
  dateMustNotBeNull: "Si prega di fornire un periodo di tempo per il ritiro del pacco.",
  registerPickupConfirmation: "Confermi questo periodo di tempo per il ritiro?",
  errorOfSystem: "Errore di sistema",
  changeStatusToSent: "Cambia stato in Inviato",
  confirmOrderWasSent2: "Vuoi confermare che l'ordine è stato spedito?",
  productIsYetToBeShipped: "Il tuo prodotto deve ancora essere spedito",
  searchByStoreName: "Cerca per nome del negozio",
  talkToSeller: "Parla con il venditore",
  talkToBuyer: "Parla con l'acquirente",
  talkTo: "Parla con",
  send: "Invia",
  noMessages: "Nessun messaggio",
  cannotExceed250Chars: "Il tuo messaggio non può superare i 250 caratteri",
  messageHasPersonalNumber:
    "Il tuo messaggio contiene un numero di telefono personale. Rimuovilo e riprova.",
  serverError: "Si è verificato un errore del server, riprova più tardi.",
  dowloadYourLabelAndPutInYourProduct:
    "Controlla l'etichetta, posiziona il tuo prodotto e controlla se il prodotto viene ritirato",
  readyForPickup: "Pronto per il ritiro",
  sellerIsPreparingYourOrder: "Il venditore sta preparando il tuo ordine",
  productWasPickedUp: "Informa che il prodotto è stato ritirato",
  youconfirmOrderWasSent: "Confermi che hai ritirato questo ordine?",
  waitingForBuyerWithdrawal: "In attesa del ritiro dell'acquirente",
  productIsReadyToPickup: "Il tuo prodotto è pronto per essere ritirato",
};

export default ordersDictIT;
