const payPalCheckoutButtonDictPT = {
  unexpectedErrorTransaction: 'Erro inesperado ao registrar.',
  invalidUserPlan: 'Plano de usuário inválido.',
  emailConfirmationTitle: 'Registrado com sucesso!',
  emailConfirmationMessage: 'Cheque seu email para ativar sua conta.',
  upgradedSuccessfully: 'Plano da conta atualizado com sucesso!',
  upgradedSuccessfullyText: 'Você precisará inserir suas credenciais de login novamente. Você será redirecionado para a tela de login em 20 segundos, ou pode pressionar o botão abaixo.',
  youWillBeRedirectedIn: 'Você será redirecionado em',
  seconds: 'segundos.',
}

export default payPalCheckoutButtonDictPT