const userDashboardHomeDictEN = {
  bonus: "Daily income",
  balance: "Balance",
  inbound: "Inbound",
  outbound: "Outbound",
  every: "Every ",
  AmountIsAutomaticallyTransferredToWallet:
    " the amount is automatically transferred to the main wallet.",
  currentlyYouCan: "Currently you can use up to ",
  ofYourExtracoin: " from your Extracredit balance to pay for your purchases.",
  extracoinOnlyBetweenUsers: "Extracredit is only for purchases and transfers between users!",
  indirects: "Indirects",
  stats: "Statistics",
  plan: "Plan",
  subscription: "Subscription",
  daysLeft: " days left",
  activation: "Activation",
  seeAllDirects: "See all directs",
  youDontHaveAny: "You don't have any direct users in your economic circle yet.",
  days: "days",
  months: "months",
  year: "year",
  numberOfUsersInNetwork: "Number of users added to your network",
  date: "Date",
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  quantity: "Quantity",
  referralLinks: "Referral links",
  copy: "Copy",
  copiedToClipboard: "Copied to clipboard.",
  copied: "Copied",
  notActive: "Inactive User",
  balanceCommission: "commissions",
  balanceSale: "sales",
  message: "Message",
  imAware: "I'm aware",
  alert: "Alert",
  notEnabled:
    "Your account cannot ship products at this time. Edit your profile address to a valid address.",
  inAnalysis: "Your provided package pickup address is currently being under analysis.",
  enabled: "Your provided package pickup address is apt to ship products.",
  thisIsPickupAddress: "This address will be used by shipping companies to pickup your packages",
  youDonthaveFinancialPassword: "You don't have a financial password!",
  wantCreateFinancialPassword: "Do you want to create a financial password?",
  passwordCreatedSuccessfully: "Password created successfully! Please check your email!",
  youAlreadyHaveAFinancialPassword: "You already have a financial password!",
  youWantToCreateNewFinancialPassword: "Do you want to create a new financial password?",
  createNewFinancialPassword: "Create new financial password",
  renewSubscriptionValue: "Renew subscription value",
  referralLinksExplanation:
    "Use these links to register new users in the various plans and automatically increase your economic circle by earning commissions and daily income according to our compensation plan.",
  profile: "Profile",
  activationRequirements: "For activation, 1 registration or 1 purchase per month is required.",
  safePayment: "Safe payment",
};

export default userDashboardHomeDictEN;
