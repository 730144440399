import { Button } from "primereact/button";
import localeText from "../../../../locale";
import useButtonAddToCart from "../../hooks/useButtonAddToCart";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";

const ButtonAddToCart = ({
  productInfo,
  currentVariation,
  currentAttribute1Value,
  currentAttribute2Value,
  productQty,
}) => {
  const { showErrorToast } = useToastContext();
  const { addToCart, loadingOrder } = useButtonAddToCart({
    currentVariation: currentVariation,
    currentAttribute1Value,
    currentAttribute2Value,
    productQty,
    productInfo,
    onErrorAddToCart: (code) => showErrorToast({ code }),
    onErrorDownloadableAndNonDownloadable: () =>
      showErrorToast({
        detail: "downloadableAndNonDownloadable",
      }),
    onErrorData: (code) => showErrorToast({ code }),
    onErrorItemAlreadyInCart: () => showErrorToast({ detail: "itemAlreadyInCart" }),
    onErrorOneSeller: () => showErrorToast({ detail: "onlyOneSeller" }),
  });

  const { product } = productInfo.data;

  const isDisabled = loadingOrder ? "opacity-80 pointer-events-none" : "";
  const buttonClass = `uppercase font-medium py-4 px-6 xl:w-max ${isDisabled}`;

  if (product?.user?.username === sessionStorage.getItem("__extraconomy_username")) {
    return (
      <div>
        <Button
          label={localeText("addToCart")}
          className={`${buttonClass} w-full`}
          icon="pi pi-shopping-cart"
          onClick={() => showErrorToast({ detail: "errorThisProductIsYours" })}
          loading={loadingOrder}
        />
      </div>
    );
  }

  return (
    <>
      {!product.downloadable && !product.service ? (
        <Button
          label={
            !currentVariation && productInfo.data.attributes.length === 1
              ? `${localeText("selectOneAttribute")} "${productInfo.data.attributes[0].attribute}"`
              : !currentVariation && productInfo.data.attributes.length === 2
              ? `${localeText("selectTwoAttributes")} "${
                  productInfo.data.attributes[0].attribute
                }" ${localeText("and")} "${productInfo.data.attributes[1].attribute}"`
              : localeText("addToCart")
          }
          className={buttonClass}
          icon="pi pi-shopping-cart"
          disabled={
            (productInfo.data.productVariations.length > 0 &&
              ((productInfo.data.attributes.length === 2 &&
                !(currentAttribute1Value && currentAttribute2Value)) ||
                (productInfo.data.attributes.length === 1 && !currentAttribute1Value))) ||
            product.amountInStock === 0 ||
            (currentVariation && currentVariation.amountInStock === 0) ||
            (productInfo.data.attributes.length > 0 &&
              productInfo.data.productVariations.length === 0) ||
            productQty === 0
          }
          onClick={() => addToCart()}
          loading={loadingOrder}
        />
      ) : (
        <div className="xl:align-self-end">
          <Button
            label={localeText("addToCart")}
            className={`${buttonClass} w-full`}
            icon="pi pi-shopping-cart"
            onClick={() => addToCart()}
            loading={loadingOrder}
          />
        </div>
      )}
    </>
  );
};

export default ButtonAddToCart;
