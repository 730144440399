import localeText from '../../../../locale';

import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import googleplay_badge from "../../../images/pages/googleplay_badge.png";
import appstore_badge from "../../../images/pages/appstore_badge.svg";
import useCurrentOS from "../../hooks/useCurrentOS";
import LanguageSelectionButton from "../../../../components/LanguageSelectionButton/LanguageSelectionButton";
import { MenuItems as LandingCenterMenuItems } from "../LandingCenterMenu/LandingCenterMenu";

const LandingMenu = ({ loading, isAdmin, userInfo }) => {

  const OS = useCurrentOS();

  return (
    <ul id="landing-menu" className="xl:flex xl:align-items-center xl:justify-content-end block xl:overflow-visible overflow-auto">
      <LandingCenterMenuItems showOnMobile />
      <li className="xl:ml-0">
        <Link
          to="/shop"
          className="a-no-hover p-0 xl:mx-4 ml-4 xl:px-0 px-4 xl:py-0 py-3 text-white hover:text-green-600 uppercase font-bold transition-all text-sm flex flex-row align-items-center"
        >
          <i className="pi pi-shopping-bag mr-2" style={{ color: "#46a748" }}></i>
          {localeText("shop")}
        </Link>
      </li>
      <li className="xl:ml-0 md:ml-6 ">
        <LanguageSelectionButton />
      </li>
      <li className="xl:mr-3 xl:mt-0 mt-4 flex justify-content-center">
        <a href="https://extracoin.site/" className="a-no-hover p-0 xl:ml-1">
          <Button
            className="p-button-warning p-button text-sm m-0" style={{height: '40px'}}
            label={<div className="flex flex-column justify-content-center">
                <b style={{color: 'white', fontSize: '10px'}}>Extracroin </b>
                <b style={{color: 'white', fontSize: '10px', 'whiteSpace': 'nowrap' }}>token EXC</b>
              </div>}
            icon={<img alt="Extracredit" src="/assets/layout/images/icone_extracoin_site.webp" style={{width: '30px', marginRight: '10px'}}
            />}
          />
        </a>
      </li>
      <li className="mt-4 text-center xl:mt-1 xl:ml-0">
        {sessionStorage.getItem("__extraconomy_token") && loading ? (
          <div className="a-no-hover p-0 mt-1">
            <i className="pi pi-spin pi-spinner text-xl text-400"></i>
          </div>
        ) : (
          <>
            {isAdmin ? (
              <Link to="/dashboard" className="a-no-hover p-0 ml-1">
                <Button
                  label="Admin Backoffice"
                  icon="pi pi-server"
                  className="-mt-1 p-button-outlined surface-0"
                />
              </Link>
            ) : (
              <>
                {userInfo && (
                  <>
                    <Link
                      to="/dashboard"
                      className="a-no-hover flex justify-content align-items-center flex-column xl:mt-0 mt-5 p-0 ml-1"
                    >
                      <img
                        className="xl:h-2rem md:h-2rem h-2rem surface-0 border-circle"
                        src={userInfo.urlAvatar}
                        alt="Icon do user"
                      ></img>
                      <span className="text-white xl:hidden">{userInfo.firstname}</span>
                    </Link>
                  </>
                )}
                {!userInfo && (
                  <>
                    {loading ? (
                      <i className="pi pi-spin pi-spinner text-600 text-xl"></i>
                    ) : (
                      <div>
                        <Link to="/login" className="a-no-hover p-0 xl:ml-1 ml-0">
                          <button type="button" className="p-button -mt-1 ml-0">
                            <span className="p-button-text flex align-items-center text-lg gap-2 font-bold uppercase">
                            <b style={{'whiteSpace': 'nowrap' }}>{localeText("login")}</b>
                              <i className="pi pi-arrow-right"></i>
                            </span>
                          </button>
                        </Link>

                        {OS && !JSON.parse(sessionStorage.getItem("__extraconomy_ismobile__")) && (
                          <>
                            <p className="xl:hidden text-white mt-3">
                              {localeText("checkApp")}
                            </p>
                            <a
                              href={
                                OS === "android"
                                  ? "https://play.google.com/store/apps/details?id=com.extraconomymobile"
                                  : "https://apps.apple.com/app/extraconomy/id6446035801"
                              }
                              className="xl:hidden"
                            >
                              <img
                                src={OS === "android" ? googleplay_badge : appstore_badge}
                                width={80}
                                alt="store"
                              />
                            </a>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </li>
    </ul>
  );
};

export default LandingMenu;
