const newProductDictEN = {
  newProductcategories: "Categories",
  subcategories: "Subcategories",
  next: "Next",
  previous: "Go back",
  clickAndSelect: "Press an item to select, press again to deselect",
  noSubcategories: "There are no subcategories for the selected category.",
  pleaseSelectCategory: "Please select a category before continuing.",
  pleaseSelectSubcategory: "Please select a subcategory before continuing.",
  addNewProduct: "Add a new product or service to your showcase",
  productInfo: "Product information",
  productFields: "Fill in the fields with the product information",
  promotionalPrice: "Discount price",
  percentExtracoin: "Credit percentage",
  dragImgs: "Drag up to 5 images here",
  uploadImages: "Upload Images",
  selectProductState: "Select the product state",
  pleaseChooseAtLeastOne: "Please choose at least one image.",
  youCanOnlyChooseUpTo5: "You can only choose up to 5 images.",
  pleaseUploadAtLeastOne: "Please choose at least one image.",
  successUploadImage: "Successfully uploaded product image.",
  productPreview: "Product Preview",
  pressRegisterOrPublish:
    'Press the "Register" button to register without publishing (product will only be visible in your product list).',
  pressRegisterAndPublish:
    'Press the "Register and Publish" button to register and make your product public for everyone.',
  pressRegisterPublishAndNotify:
    'Press the "Register, Publish and Notify" button to register, make your product public for everyone and notify system users about your new product.',
  yourMinimumExtracoinPercentage: "The minimum Extracredit percentage of your plan is",
  buy: "Buy",
  buyNow: "Buy now",
  register: "Register",
  registerAndPublish: "Register and Publish",
  registerPublishAndNotify: "Register, Publish and Notify",
  notifyToYourEconomicCircle:
    "The notification will be send to all users of your economic circle, it costs",
  notifyAll: "Notify all system users, it costs",
  insufficientCredits: "Insufficient credits",
  unexpectedErrorRegisterProduct: "Unexpected error while registering product.",
  unexpectedErrorUploadFile: "Unexpected error while uploading file.",
  percentExtracoinExplanationPt1: "You can pay up to",
  percentExtracoinExplanationPt2: "of this item's price in Extracredit credits",
  brand: "Brand",
  selectBrand: "Select your product brand",
  type: "Type",
  selectType: "Select your product type",
  gender: "Gender",
  selectGender: "Select gender",
  model: "Model",
  doYouWantDiscount: "Do you want to give a discounted price?",
  promotionalPriceMustBeLessThan: "Discount price must be cheaper than regular price.",
  variationMustHaveAValidPrice: "Variation must have valid price.",
  sendSeller: "I am responsible for the shipping of the product.",
  addToCart: "Add to cart",
  available: "unit(s) available",
  shippingInfo: "Shipping",
  byBuyer: "Buyer",
  bySeller: "Seller",
  productImages: "Product images",
  previewImages: "Preview images",
  uploadProductFile: "Upload the actual file of your downloadable product:",
  uploadProductSampleFile:
    "Upload a preview file that will be publicly available in the product page:",
  clickForASample: "Click here for a sample of this product",
  subheading: "Subheading",
  heading: "Heading",
  variationsExplanation: "You can set up variations for this product at the products list menu.",
  relatedProducts: "Related Products",
  productTechnicalInfo: "Technical Information",
  productTechnicalInfoExplanation:
    "You can provide up to 5 fields of technical information about your product",
  fieldName: "Field name",
  fieldValue: "Field value",
  errorWhileRegisteringTechnicalInfo: "Error while registering technical info.",
  useShippingService:
    "Use the shipping service? (You will need to provide your products' dimensions)",
  height: "Height",
  width: "Width",
  length: "Length",
  weight: "Weight",
  shippingUnavailableWarning: "Notice: Freight service unavailable",
  WeDoNotYetHaveShipping:
    "We do not yet have a shipping quota for senders outside of Italy, that is, products that need a shipping quota cannot be sent by our shipping service.",
  freightService: "Freight service:",
  freightServiceAvaible: "Available",
  freightServiceUnavailable: "Unavailable",
  service: "Service",
  downloadable: "Downloadable",
  typeOfProduct: "Type of product",
  pleaseSelectTypeProduct: "Please select the type of product.",
  weAreWorkingInThisFeature: "We are working on this feature.",
  chooseWeightRange: "Choose the weight range of your product",
  freight: "Freight",
  credits: "credits",
  notifyCircleExplanationt1:
    "The notification is only sent to users in your economic circle at the cost of  ",
  notifyCircleExplanationt2: "that will be deducted from your Extrabonus wallet.",
  notifyAllExplanation1: "The notification is sent to all users of the platform at the cost of",
  notifyAllExplanation2: " that will be deducted from your Extrabonus wallet.",
  notifyAllExplanation3: "The notification is sent to all users of the platform at the cost of",
  additionalShippingPriceProduct: "Additional shipping price for this product",
  without: "Without",
  free2: "Free",
  paid: "Paid",
  includesTax: "The shipping cost from our platform will be included in the value of the product.",
  youPayFreight: "You are responsible for shipping the product",
  paidFreight: "The customer pays the shipping",
  lookingTheBestShippingMethod: "We are looking for the best shipping method",
  finalPrice: "Estimated price",
  priceCalculator: "Price calculator",
  or: "or",
  valueCanNotBeNegative: "The value cannot be negative.",
  paidOrWithdrawn: "Paid or withdrawn",
  paidOrWithdrawnExplain: "The customer pays the shipping or withdraws it locally",
  freightValueExplain:
    "The shipping cost will be deducted from the final value of the product to be passed on as free to the customer.",
  freightPriceGreaterThanPrice: "The value of the product cannot be less than the shipping cost.",
  shippingIncluded: "Shipping included",
  priceCharged: "Price charged",
  amountToBeReceived: "Amount to be received",
  chooseWeightRangeToCalculateShipping: "Choose the weight range to calculate the shipping.",
  discounts: "Discounts",
  included: "Included (With our fright)",
  includedWithoutSendcloud: "Included (Without our fright)",
  theCustomerWillPay: "The customer will pay",
  ifYouWishToReceive: "If you wish to receive",
  additionals: "Additionals",
  thenYouWillHaveToCharge: "Then you will have to charge",
  condition: "Condition",
  selectCondition: "Select the product condition",
  labeled: "Labeled",
  notLabeled: "Not labeled",
  excellent: "Excellent",
  good: "Good",
  discrete: "Discrete",
  stockGreaterThanZero: "The stock must be greater than zero.",
  minimunValueError: "The minimum volume is 100 cubic centimeters. Change length, width or height.",
  simulateLeastOneShippingValue: "Simulate at least one shipping value.",
  enterZipCodeToSimulateEndCustomer:
    "Enter a zip code to simulate the end customer and add the value to the product. The shipping cost will be deducted from the value of the product when purchased.",
  simulate: "Simulate",
  addValidPostalCode: "Add a valid postal code",
  withOurFright: "With our fright",
  withoutOurFright: "Without our fright",
  youHaveExceededThe5PublishedProducts: "You have exceeded the limit of 5 published products.",
  youHaveExceededThe5PublishedProductsExplanation:
    "You have exceeded the limit of 5 published products. To publish a new product, you must unpublishe one of the products already published.",
  youCannotPublishProductsYet: "You cannot publish products yet.",
  firstCreateYourStore: "First create your store.",
  populateCashback: "Fill in the cashback percentage",
};

export default newProductDictEN;
