import { useEffect, useState } from "react";
import GetUserInfoService from "../service/GetUserInfoService";

const useGetFavorites = ({ onError }) => {
  const [favorites, setFavorites] = useState();
  const getUserInfoService = new GetUserInfoService();

  const loadFavorites = async () => {
    try {
      const res = await getUserInfoService.getFavorites(
        sessionStorage.getItem("__extraconomy_token"),
        sessionStorage.getItem("__extraconomy_username")
      );
      if (res.messageCode !== 200001) {
        onError(res.messageCode);
        if (res.messageCode === 500000) console.log(res);
      } else setFavorites(res.result);
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        sessionStorage.removeItem("__extraconomy_token");
        sessionStorage.removeItem("__extraconomy_username");
        window.location.replace("/login");
      }
      console.log(e);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("__extraconomy_token")) loadFavorites(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { favorites };
};

export default useGetFavorites;
