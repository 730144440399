const registerUserDictES = {
  referralUsername: "Nombre de usuario del referidor",
  nameOfUserRefferalCodeInvalid: "Nombre de usuario del referidor inválido.",
  doYouHave: "Tienes un referidor?",
  yes: "Sí",
  no: "No",
  voucher: "Código del voucher",
  doYouHaveVoucher: "Tienes un voucher?",
  username: "Nombre de usuário",
  password: "Contraseña",
  confirmPassword: "Confirmar contraseña",
  firstName: "Nombre",
  surname: "Apellido",
  documentNumber: "RFC",
  selectCurrency: "Seleccione una moneda",
  terms: "Yo acepto los termos y condiciones.",
  marketingEmail: "Recibir correos electrónicos sobre actualizaciones y promociones?",
  recaptcha: "Volver y verificar ReCaptcha",
  signUp: "Registrar",
  proceedToPayment: "Proceder al Pago",
  payWithExtracoin: "Pagar con extracoin",
  coupon: "Cupón de registro",
  uploadDocPic: "Cargar foto del documento",
  on: "en",
  salePromoterRefExplanation:
    "Sólo se puede registrar como Promoter mediante un link de referencia de una Manager.",
  // toast
  referralUserNotFound: "Usuario referidor no encontrado.",
  invalidCoupon: "Cupón de crédito inválido.",
  couponDoesNotBelong: "El cupón no pertenece a este usuario referidor.",
  paymentCheckout: "Pago",
  phoneConfirmation: "Confirmación por teléfono",
  phoneConfirmCodeExplanationPt1: "Enviamos un mensaje vía SMS para",
  phoneConfirmCodeExplanationPt2:
    "con su código de confirmación, por favor introduzca el código abajo para finalizar su registro.",
  phoneConfirmCode: "Código de confirmación",
  pleaseUploadDocPic: "Cargue una foto de su documento de identificación",
  shippingRules: "Aviso: ¡Aún no es posible usar la cotización de envío!",
  shippingRulesExplanation:
    "¡Nuestro servicio de envíos está disponible sólo para usuarios que remiten de direcciones en Italia!",
  iKnow: "Estoy consciente",
  alreadyHaveAccount: "Ya tienes una cuenta?",
  emailCanBeSpam: "Revise su carpeta de spam, el correo puede estar allí.",
  doubts: "Dudas?",
};

export default registerUserDictES;
