const userDashboardHomeDictES = {
  bonus: "Renta diaria",
  balance: "Saldo",
  inbound: "Crédito",
  outbound: "Débito",
  every: "Cada",
  AmountIsAutomaticallyTransferredToWallet:
    " el monto se transfiere automáticamente a la billetera principal.",
  currentlyYouCan: "Actualmente puedes utilizar hasta ",
  ofYourExtracoin: " de tu saldo Extracrédito para pagar tus compras.",
  extracoinOnlyBetweenUsers: "Extracredit es sólo para compras y transferencias entre usuarios!",
  indirects: "Indirectos",
  stats: "Estadísticas",
  plan: "Plan",
  subscription: "Firma",
  daysLeft: " días restantes",
  activation: "Activación",
  seeAllDirects: "Ver todos los directos",
  youDontHaveAny: "Usted no tiene ninguno usuario directo en su círculo económico aún",
  days: "días",
  months: "meses",
  year: "año",
  numberOfUsersInNetwork: "Número de usuarios agregados a su red",
  date: "Fecha",
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  quantity: "Cuantidad",
  referralLinks: "Links de referencia",
  copy: "Copiar",
  copiedToClipboard: "Copiado para la área de transferencia.",
  copied: "Copiado",
  notActive: "Usuario inactivo",
  balanceCommission: "comisiones",
  balanceSale: "ventas",
  message: "Mensaje",
  imAware: "Estoy consciente",
  alert: "Alerta",
  notEnabled:
    "Su cuenta no puede enviar productos en este momento. Edite la dirección de su perfil a una dirección válida.",
  inAnalysis:
    "Su dirección de recogida del paquete proporcionada está siendo analizada actualmente.",
  enabled: "La dirección de recogida del paquete proporcionada es apta para enviar productos.",
  thisIsPickupAddress:
    "Esta dirección será utilizada por las empresas de envío para recoger sus paquetes",
  youDonthaveFinancialPassword: "¡No tienes una contraseña financiera!",
  wantCreateFinancialPassword: "¿Quieres crear una contraseña financiera?",
  passwordCreatedSuccessfully:
    "¡Contraseña creada con éxito! Por favor, compruebe su correo electrónico!",
  youAlreadyHaveAFinancialPassword: "¡Ya tienes una contraseña financiera!",
  youWantToCreateNewFinancialPassword: "¿Quieres crear una nueva contraseña financiera?",
  createNewFinancialPassword: "Crear nueva contraseña financiera",
  renewSubscriptionValue: "Renovar valor de la suscripción",
  referralLinksExplanation:
    "Utilice estos enlaces para registrar nuevos usuarios en los distintos planes y aumente automáticamente su círculo económico ganando comisiones e ingresos diarios según nuestro plan de compensación.",
  profile: "Perfil",
  activationRequirements: "Para la activación se requiere 1 registro o 1 compra por mes.",
  safePayment: "Pago seguro",
};

export default userDashboardHomeDictES;
