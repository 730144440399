const loginDictPT = {
  to: 'na',
  signInWith: "Entre com as credenciais da sua conta ativada",
  forgotPassword: 'Esqueceu sua senha?',
  sendEmail: 'Enviar Email',
  // toast
  pleaseFillOut: 'Por favor preencha os campos de usuário e senha.',
  pleaseReverify: 'Por favor verifique novamente o ReCaptcha.',
  incorrectCredentials: 'Credenciais incorretas.',
  pleaseCheckEmail: 'Por favor verifique seu endereço de email para ativar sua conta.',
  pleaseCheckEmailTemp: 'Por favor verifique seu email para sua nova senha temporária.',
  userNotFound: 'Usuário não encontrado.',
  thisEmailDoesnt: "Este email não corresponde a este usuário.",
  error: 'Erro',
  success: 'Sucesso'
}

export default loginDictPT