const newProductDictPT = {
  newProductcategories: "Categorias",
  subcategories: "Subcategorias",
  next: "Próximo",
  previous: "Voltar",
  clickAndSelect: "Pressione um item para selecionar, pressione novamente para desmarcar",
  noSubcategories: "Não há subcategorias para a categoria selecionada.",
  pleaseSelectCategory: "Por favor selecione uma categoria antes de continuar.",
  pleaseSelectSubcategory: "Por favor selecione uma subcategoria antes de continuar.",
  addNewProduct: "Adicionar produto ou serviço à vitrine",
  productInfo: "Informações do produto",
  productFields: "Preencha os campos com as informações do produto",
  promotionalPrice: "Preço com desconto",
  percentExtracoin: "Percentual de crédito",
  dragImgs: "Arraste até 5 imagens aqui",
  uploadImages: "Enviar Imagens",
  selectProductState: "Selecione o estado do produto",
  pleaseChooseAtLeastOne: "Por favor escolha pelo menos uma imagem.",
  youCanOnlyChooseUpTo5: "Você só pode escolher até 5 imagens.",
  pleaseUploadAtLeastOne: "Escolha pelo menos uma imagem.",
  successUploadImage: "Imagem do produto enviada com sucesso.",
  productPreview: "Visualização prévia do produto",
  pressRegisterOrPublish:
    'Pressione o botão "Cadastrar" para cadastrar sem publicar (o produto ficará visível apenas na sua lista de produtos).',
  pressRegisterAndPublish:
    'Pressione o botão "Cadastrar e Publicar" para cadastrar e tornar seu produto público para todos.',
  pressRegisterPublishAndNotify:
    'Pressione o botão "Cadastrar, Publicar e Notificar" para cadastrar, tornar seu produto público para todos e notificar os usuários do sistema sobre o seu novo produto.',
  yourMinimumExtracoinPercentage: "O percentual mínimo de Extracredit do seu plano é",
  buy: "Comprar",
  buyNow: "Comprar agora",
  register: "Cadastrar",
  registerAndPublish: "Cadastrar e Publicar",
  registerPublishAndNotify: "Cadastrar, Publicar e Notificar",
  notifyToYourEconomicCircle: "Notificar todos os usuários do seu círculo econômico, custa",
  notifyAll: "Notificar todos os usuários do sistema, custa",
  insufficientCredits: "Créditos insuficientes",
  unexpectedErrorRegisterProduct: "Erro inesperado ao registrar o produto.",
  unexpectedErrorUploadFile: "Erro inesperado ao carregar o arquivo.",
  percentExtracoinExplanationPt1: "Você pode pagar até",
  percentExtracoinExplanationPt2: "do preço deste item em créditos Extracredit",
  brand: "Marca",
  selectBrand: "Selecione a marca do seu produto",
  type: "Tipo",
  selectType: "Selecione o tipo do seu produto",
  gender: "Gênero",
  selectGender: "Selecione o gênero",
  model: "Modelo",
  doYouWantDiscount: "Você quer dar um preço com desconto?",
  promotionalPriceMustBeLessThan: "Preço com desconto deve ser mais barato que o preço regular.",
  variationMustHaveAValidPrice: "Variação deve ter um preço válido.",
  sendSeller: "Sou responsável pelo envio do produto.",
  addToCart: "Adicionar ao carrinho",
  available: "unidade(s) disponível(is)",
  shippingInfo: "Envio",
  byBuyer: "Comprador",
  bySeller: "Vendedor",
  productImages: "Imagens do produto",
  previewImages: "Imagens prévia",
  uploadProductFile: "Carregue o arquivo real do seu produto para download:",
  uploadProductSampleFile:
    "Carregue um arquivo de prévia que estará disponível publicamente na página do produto:",
  clickForASample: "Clique aqui para obter uma amostra deste produto",
  subheading: "Subtítulo",
  heading: "Cabeçalho",
  variationsExplanation:
    "Você pode configurar variações para este produto no menu da lista de produtos.",
  relatedProducts: "Produtos Relacionados",
  productTechnicalInfo: "Informações Técnicas",
  productTechnicalInfoExplanation:
    "Você pode fornecer até 5 campos de informações técnicas sobre seu produto",
  fieldName: "Nome do campo",
  fieldValue: "Valor do campo",
  errorWhileRegisteringTechnicalInfo: "Erro ao registrar informações técnicas.",
  useShippingService:
    "Usar o serviço de frete? (Você precisará fornecer as dimensões de seus produtos)",
  height: "Altura",
  width: "Largura",
  length: "Comprimento",
  weight: "Peso",
  shippingUnavailableWarning: "Aviso: Serviço de frete indisponível",
  WeDoNotYetHaveShipping:
    "Nós ainda não possuimos cota de frete para remetentes fora da Itália, ou seja, produtos que precisão da cota de frete não poderão ser enviados pelo nosso serviço de frete.",
  freightService: "Serviço de frete:",
  freightServiceAvaible: "Disponível",
  freightServiceUnavailable: "Indisponível",
  service: "Serviço",
  downloadable: "Baixável",
  typeOfProduct: "Tipo de produto",
  pleaseSelectTypeProduct: "Por favor selecione o tipo do produto.",
  weAreWorkingInThisFeature: "Estamos trabalhando nessa funcionalidade.",
  chooseWeightRange: "Escolha a faixa de peso do produto",
  freight: "Frete",
  credits: "créditos",
  notifyCircleExplanationt1:
    "A notificação é enviada apenas para usuários do seu círculo econômico ao custo de",
  notifyCircleExplanationt2: "que serão descontados da sua carteira Extrabonus.",
  notifyAllExplanation1: "A notificação é enviada a todos os usuários da plataforma ao custo de",
  notifyAllExplanation2: "que serão descontados da sua carteira Extrabonus.",
  additionalShippingPriceProduct: "Preço adicional de envio do produto",
  without: "Sem",
  free2: "Grátis",
  paid: "Pago",
  includesTax: "Será incluído o valor do frete da nossa plataforma no valor do produto",
  youPayFreight: "Você é responsável pelo frete do produto",
  paidFreight: "O cliente paga o frete",
  lookingTheBestShippingMethod: "Estamos buscando a melhor forma de envio",
  finalPrice: "Preço estimado",
  priceCalculator: "Calculadora de preços",
  or: "ou",
  valueCanNotBeNegative: "O valor não pode ser negativo.",
  paidOrWithdrawn: "Pago ou retirada no local",
  paidOrWithdrawnExplain: "O cliente paga o frete ou retira no local",
  freightValueExplain:
    "O valor do frete será descontado do valor do final do produto para ser repassado como grátis para o cliente.",
  freightPriceGreaterThanPrice: "O valor do produto não pode ser menor que o valor do frete.",
  shippingIncluded: "Frete incluso",
  priceCharged: "Preço cobrado",
  amountToBeReceived: "Valor a receber",
  chooseWeightRangeToCalculateShipping: "Escolha a faixa de peso para calcular o frete.",
  discounts: "Descontos",
  included: "Incluído (Com o nosso frete)",
  includedWithoutSendcloud: "Incluído (Sem o nosso frete)",
  theCustomerWillPay: "O cliente pagará",
  ifYouWishToReceive: "Se você deseja receber",
  additionals: "Adicionais",
  thenYouWillHaveToCharge: "Então terá que cobrar",
  condition: "Condição",
  selectCondition: "Selecione a condição do produto",
  labeled: "Etiquetado",
  notLabeled: "Não etiquetado",
  excellent: "Excelente",
  good: "Bom",
  discrete: "Discreto",
  stockGreaterThanZero: "O estoque deve ser maior que zero.",
  minimunValueError:
    "O volume mínimo é de 100 centímetros cúbicos. Altere o comprimento, largura ou altura.",
  simulateLeastOneShippingValue: "Simule pelo menos um valor de frete.",
  enterZipCodeToSimulateEndCustomer:
    "Coloque um código postal para simular o cliente final e adicione o valor no produto. O valor do frete será descontado do valor do produto na comprar dele.",
  simulate: "Simular",
  addValidPostalCode: "Adicione um código postal válido",
  withOurFright: "Com o nosso frete",
  withoutOurFright: "Sem o nosso frete",
  youHaveExceededThe5PublishedProducts: "Você excedeu o limite de 5 produtos publicados.",
  youHaveExceededThe5PublishedProductsExplanation:
    "Para publicar mais produtos, você precisa despublicar um produto.",
  youCannotPublishProductsYet: "Você não pode publicar produtos ainda.",
  firstCreateYourStore: "Primeiro crie sua loja.",
  populateCashback: "Preencha o percentual de cashback",
};

export default newProductDictPT;
