import { Button } from "primereact/button";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../../../components/Loading/Loading";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";
import { useToggle } from "../../../../hooks/useToggle";
import localeText from "../../../../locale";
import AnswerModal from "./components/AnswerModal";
import AskModal from "./components/AskModal";
import Question from "./components/Question";
import QuestionsModal from "./components/QuestionsModal";
import useGetQuestions from "./hooks/useGetQuestions";
import useReplyQuestion from "./hooks/useReplayQuestion";
import useApiStatus from "../../../../hooks/useApiStatus";

const ProductQuestionsArea = ({
  productInfoID,
  productOwner,
  productOwnerFirstName,
  productQuestionsRef,
}) => {
  const { showErrorToast } = useToastContext();
  const { value, setAsFalse, setAsTrue } = useToggle();
  const {
    value: isVisibleAnswerModal,
    setAsFalse: setNotVisibleAnswerModal,
    setAsTrue: setVisibleVisibleAnswerModal,
  } = useToggle();
  const {
    value: isVisibleAskModal,
    setAsFalse: setNotVisibleAskModal,
    setAsTrue: setVisibleAskModal,
  } = useToggle();
  const { questions, isLoading, setQuestions } = useGetQuestions({
    productId: productInfoID,
    onError: (code) => showErrorToast({ code }),
  });
  const {
    answer,
    setAnswer,
    isLoading: isLoadingAnswer,
    postAnswer,
    setCurrentQuestion,
    currentQuestion,
  } = useReplyQuestion({
    idProduct: productInfoID,
    setAsFalse: setNotVisibleAnswerModal,
    setQuestions,
    questions,
  });
  const { isLoading: isLoadingAnwserModal, setIsLoading: setIsLoadingAnwserModal } = useApiStatus();
  const history = useHistory();
  const location = history?.location;
  const params = new URLSearchParams(location?.search);
  const questionId = params?.get("questionId");

  const handleSetVisibleAskModal = () => {
    const username = sessionStorage.getItem("__extraconomy_username");
    if (username) {
      setVisibleAskModal();
      return;
    }
    const { pathname, search } = location;
    const from = `${pathname}${search}`;
    history.push("/login", { from });
  };

  useEffect(() => {
    const isOwnerProduct = productOwner === sessionStorage.getItem("__extraconomy_username");
    const from = `${location.pathname}${location.search}`;
    if (!isOwnerProduct && questionId) history.push("/login", { from });

    if (questionId && questions && questions.length > 0 && isOwnerProduct) {
      setIsLoadingAnwserModal(true);
      setVisibleVisibleAnswerModal();

      const currentQuestionFound = questions.find((q) => q.id === Number(questionId));
      setCurrentQuestion({
        id: Number(questionId),
        question: currentQuestionFound?.question,
        firstName: currentQuestionFound?.user?.firstname,
        dateQuestion: currentQuestionFound?.dateQuestion,
        answer: currentQuestionFound?.answer,
        dateAnswer: currentQuestionFound?.dateAnswer,
        productOwnerFirstName: productOwnerFirstName,
      });
      setIsLoadingAnwserModal(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, questions]);

  if (isLoading) return <Loading />;
  const firstFiveQuestions = questions.slice(0, 5);
  const isNotProductOwner = productOwner !== sessionStorage.getItem("__extraconomy_username");

  return (
    <>
      <QuestionsModal
        headerText={`${localeText("questionsAndAnswers")} (${questions.length})`}
        setAsFalse={setAsFalse}
        visible={value}
        questions={questions}
        onwerProduct={productOwner}
        isLoading={isLoading}
        productOwnerFirstName={productOwnerFirstName}
      />
      <AskModal
        headerText={`${localeText("doQuestion")}`}
        setAsFalse={setNotVisibleAskModal}
        visible={isVisibleAskModal}
        idProduct={productInfoID}
        setAsTrue={setVisibleAskModal}
        setQuestions={setQuestions}
      />
      {questionId && (
        <AnswerModal
          visible={isVisibleAnswerModal}
          isLoadingAnwserModal={isLoadingAnwserModal}
          setAsFalse={setNotVisibleAnswerModal}
          headerText={`${localeText("reply")}`}
          setAnswer={setAnswer}
          answer={answer}
          isLoading={isLoadingAnswer}
          postAnswer={postAnswer}
          currentQuestion={currentQuestion}
        />
      )}
      <div
        ref={productQuestionsRef}
        id="productQuestions"
        className={`border-solid border-round lg:p-6 p-4 border-1 border-gray-300 overflow-hidden bg-white`}
      >
        <div className="flex align-items-center gap-2">
          <h1 className="font-bold mb-0 block lg:text-3xl md:text-xl text-lg text-primary">
            {localeText("questionsAndAnswers")} ({questions.length})
          </h1>
        </div>
        {(isNotProductOwner || (!isNotProductOwner && firstFiveQuestions.length > 0)) && <hr />}

        {firstFiveQuestions && firstFiveQuestions.length !== 0 && (
          <>
            <div className="flex flex-column gap-3">
              {firstFiveQuestions.map((question, i) => (
                <Question
                  key={i}
                  question={question.question}
                  firstName={question.user.firstname}
                  dateQuestion={question.dateQuestion}
                  onwerProduct={productOwner}
                  questionId={question.id}
                  answer={question?.answer}
                  dateAnswer={question?.dateAnswer}
                  productOwnerFirstName={productOwnerFirstName}
                />
              ))}
            </div>
          </>
        )}
        <div className="flex gap-2">
          {isNotProductOwner && (
            <Button className="mt-2" onClick={handleSetVisibleAskModal}>
              <span className="ml-2">{localeText("doQuestionToSeller")}</span>
            </Button>
          )}
          {questions.length > 5 && (
            <Button className="mt-2" onClick={setAsTrue}>
              {localeText("showMore")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductQuestionsArea;
