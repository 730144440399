const registerUserDictIT = {
  referralUsername: "Nome utente di riferimento",
  nameOfUserRefferalCodeInvalid: "Nome utente di riferimento non valido.",
  doYouHave: "Hai un riferimento?",
  yes: "Sì",
  no: "No",
  voucher: "Codice voucher",
  doYouHaveVoucher: "Hai un voucher?",
  username: "Nome utente",
  password: "Password",
  confirmPassword: "Conferma password",
  firstName: "Nome di battesimo",
  surname: "Cognome",
  documentNumber: "Codice Fiscale",
  documentNumberPJ: "Partita IVA",
  selectCurrency: "Seleziona una valuta",
  terms: "Accetto i termini e le condizioni.",
  marketingEmail: "Ricevere email su aggiornamenti e promozioni?",
  recaptcha: "Ricontrolla ReCaptcha",
  signUp: "Iscrizione",
  proceedToPayment: "Procedere al Pagamento",
  payWithExtracoin: "Paga con extracoin",
  coupon: "Buono di Registrazione",
  uploadDocPic: "Carica la foto del documento",
  on: "su",
  salePromoterRefExplanation:
    "Puoi registrarti come Promoter solo tramite un link di riferimento da un Manager.",
  // toast,
  referralUserNotFound: "Utente di riferimento non trovato.",
  invalidCoupon: "Coupon di credito non valido.",
  couponDoesNotBelong: "Il coupon non appartiene a questo utente referente.",
  paymentCheckout: "Pagamento",
  phoneConfirmation: "Conferma telefonica",
  phoneConfirmCodeExplanationPt1: "Abbiamo inviato un messaggio via SMS a",
  phoneConfirmCodeExplanationPt2:
    "con il tuo codice di conferma, inserisci il codice qui sotto per completare la registrazione.",
  phoneConfirmCode: "Codice di conferma",
  pleaseUploadDocPic: "Carica una foto del tuo documento d'identità",
  shippingRules: "Avviso: Non è ancora possibile utilizzare il preventivo di spedizione!",
  shippingRulesExplanation:
    "Il nostro servizio di spedizione è disponibile solo per gli utenti che spedicono da indirizzi in Italia!",
  iKnow: "Sono consapevole",
  alreadyHaveAccount: "Hai già un account?",
  emailCanBeSpam: "Controlla la tua cartella spam, l'email potrebbe esserci.",
  doubts: "Dubbi?",
};

export default registerUserDictIT;
